import React, { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../../Hooks/useUser';
import Loading from '../Loading';

const PageNotFound = React.lazy(() => import('../../Pages/PageNotFound'));

const userPage = {
  tools: React.lazy(() => import('../../Pages/User/tools')),
  'tools/[id]': React.lazy(() => import('../../Pages/User/tools/[id]')),
  credits: React.lazy(() => import('../../Pages/User/credits')),
  groups: React.lazy(() => import('../../Pages/User/groups')),
  'groups/create': React.lazy(() => import('../../Pages/User/groups/register')),
  'groups/join': React.lazy(() => import('../../Pages/User/groups/join')),
  'groups/[id]': React.lazy(() => import('../../Pages/User/groups/[id]')),
  library: React.lazy(() => import('../../Pages/User/library')),
  contact: React.lazy(() => import('../../Pages/User/contact-us')),
  faq: React.lazy(() => import('../../Pages/User/faq')),
  'library/[id]': React.lazy(() => import('../../Pages/User/library/[id]')),
  notebooks: React.lazy(() => import('../../Pages/User/notebooks')),
  give: React.lazy(() => import('../../Pages/User/gives')),
  // orders: React.lazy(() => import('../../Pages/User/recent-purchases')),
  // 'orders/[id]': React.lazy(() => import('../../Pages/User/recent-purchases/[id]')),
  'profile/[id]': React.lazy(() => import('../../Pages/User/profile/[id]'))
  // cart: React.lazy(() => import('../../Pages/User/cart')),
  // shop: React.lazy(() => import('../../Pages/User/products')),
  // 'shop/[id]': React.lazy(() => import('../../Pages/User/products/[id]'))
};

const adminPage = {
  tools: React.lazy(() => import('../../Pages/Admin/tools')),
  'tools/create': React.lazy(() => import('../../Pages/Admin/tools/create')),
  'tools/[id]': React.lazy(() => import('../../Pages/Admin/tools/[id]')),
  users: React.lazy(() => import('../../Pages/Admin/users')),
  'users/[id]': React.lazy(() => import('../../Pages/Admin/users/[id]')),
  groups: React.lazy(() => import('../../Pages/Admin/groups')),
  'groups/create': React.lazy(() => import('../../Pages/Admin/groups/create')),
  'groups/[id]': React.lazy(() => import('../../Pages/Admin/groups/[id]')),
  disclaimers: React.lazy(() => import('../../Pages/Admin/disclaimers')),
  'profile/[id]': React.lazy(() => import('../../Pages/User/profile/[id]')),
  contact: React.lazy(() => import('../../Pages/User/contact-us')),
  faq: React.lazy(() => import('../../Pages/User/faq')),
  orders: React.lazy(() => import('../../Pages/Admin/orders')),
  'orders/[id]': React.lazy(() => import('../../Pages/Admin/orders/[id]')),
  announcement: React.lazy(() => import('../../Pages/Admin/announcement')),
  'announcement/create': React.lazy(() => import('../../Pages/Admin/announcement/create')),
  'announcement/[id]': React.lazy(() => import('../../Pages/Admin/announcement/[id]')),
  products: React.lazy(() => import('../../Pages/Admin/products')),
  'products/create': React.lazy(() => import('../../Pages/Admin/products/create')),
  'products/[id]': React.lazy(() => import('../../Pages/Admin/products/[id]')),
  coupons: React.lazy(() => import('../../Pages/Admin/coupons')),
  'coupons/create': React.lazy(() => import('../../Pages/Admin/coupons/create')),
  'coupons/[id]': React.lazy(() => import('../../Pages/Admin/coupons/[id]')),
  churches: React.lazy(() => import('../../Pages/Admin/churches')),
  'churches/create': React.lazy(() => import('../../Pages/Admin/churches/create')),
  'churches/[id]': React.lazy(() => import('../../Pages/Admin/churches/[id]')),
  claims: React.lazy(() => import('../../Pages/Admin/claims')),
  'claims/create': React.lazy(() => import('../../Pages/Admin/claims/create')),
  'claims/[id]': React.lazy(() => import('../../Pages/Admin/claims/[id]')),
  donations: React.lazy(() => import('../../Pages/Admin/donations')),
  'donations/[id]': React.lazy(() => import('../../Pages/Admin/donations/[id]')),
  'funded-projects': React.lazy(() => import('../../Pages/Admin/fundedProjects')),
  'funded-projects/create': React.lazy(() => import('../../Pages/Admin/fundedProjects/create')),
  'funded-projects/[id]': React.lazy(() => import('../../Pages/Admin/fundedProjects/[id]')),
  'site-settings': React.lazy(() => import('../../Pages/Admin/siteSettings'))
};

const PageRender = () => {
  const { page, id } = useParams();

  const { currentUser: user, userView } = useUser();

  let pageName = '';

  if (user) {
    if (id) {
      if (id === 'create') {
        pageName = `${page}/create`;
      } else if (id === 'join') {
        pageName = `${page}/join`;
      } else {
        pageName = `${page}/[id]`;
      }
    } else {
      pageName = `${page}`;
    }
  }

  const usePage = useMemo(() => {
    switch (true) {
      case user?.role === 'admin' && !userView:
        return adminPage;
      case user?.role === 'admin' && userView:
      case user?.role === 'user' && userView:
      case userView:
      default:
        return userPage;
    }
  }, [user?.role, userView]);

  const Page = usePage[pageName] || PageNotFound;

  return (
    <Suspense fallback={<Loading />}>
      <Page />
    </Suspense>
  );
};

export default PageRender;

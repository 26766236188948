/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Divider, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMutation, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import Thumb from '../../../views/Workbook/Thumb';
import Modal from '../../Modal';
import Button from '../../Button';
import useQuill from '../../../Hooks/useQuill';
import { putDataAPI } from '../../../Helpers/FetchAPI';

const EditWorkbook = ({ setSnack, close, workBook }) => {
  const queryClient = useQueryClient();
  const { ref: quillRef, adminModules: modules, theme } = useQuill();
  const { ref: introQuillRef, adminModules: introModules } = useQuill();
  const { ref: outroQuillRef, adminModules: outroModules } = useQuill();
  const [coverImg, setCoverImg] = useState(!!workBook.file_cover);
  const [headerImg, setHeaderImg] = useState(!!workBook.file_header);
  const [body, setBody] = useState(workBook.description);
  const [bodyIntro, setBodyIntro] = useState(workBook.intro);
  const [bodyOutro, setBodyOutro] = useState(workBook.outro);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
  });

  const workBookUpdateMutation = useMutation(
    (params) => {
      return putDataAPI(`/workbook/update-workbook/${workBook._id}`, params, {
        headers: {
          'content-type': 'multipart/form-data'
        },
        maxContentLength: 10000000,
        maxBodyLength: 10000000
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bookSection');
        queryClient.invalidateQueries('products');
        close();
      },
      onError: (err) => {
        alert(err);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      title: workBook.title,
      sku: workBook.sku,
      scoring: workBook.scoring,
      description: workBook.description || '#000000',
      colorMain: workBook.colorMain || '#000000',
      colorLightShade: workBook.colorLightShade || '#000000',
      colorDarkShade: workBook.colorDarkShade || '#000000',
      colorBackground: workBook.colorBackground || '#000000',
      file_cover: workBook.file_header ? [workBook.file_cover] : [],
      file_header: workBook.file_header ? [workBook.file_header] : [],
      intro: workBook?.intro || '',
      outro: workBook?.outro || '',
      price: workBook?.price
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('sku', values.sku);
      formData.append('scoring', values.scoring);
      formData.append('description', body);
      formData.append('colorMain', values.colorMain);
      formData.append('colorLightShade', values.colorLightShade);
      formData.append('colorDarkShade', values.colorDarkShade);
      formData.append('colorBackground', values.colorBackground);
      formData.append('file_cover', values.file_cover[0]);
      formData.append('file_header', values.file_header[0]);
      formData.append('disabled', false);
      formData.append('intro', bodyIntro);
      formData.append('outro', bodyOutro);
      formData.append('price', values.price);

      workBookUpdateMutation.mutate(formData);
      setSnack(true);
    }
  });

  const UploadComponent = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: { 'image/*': [] },
      onDrop: (acceptedFiles) => {
        setCoverImg(false);
        formik.setFieldValue('file_cover', acceptedFiles);
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
    );
  };

  const UploadComponentHeader = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: { 'image/*': [] },
      onDrop: (acceptedFiles) => {
        setHeaderImg(false);
        formik.setFieldValue('file_header', acceptedFiles);
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal>
      <div
        style={{
          overflowY: 'auto',
          height: '100%',
          width: '100%'
        }}
      >
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              width: '100%',
              padding: '1rem'
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Box style={{ width: '100%' }}>
                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={9}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="title"
                      name="title"
                      label="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={formik.touched.title && Boolean(formik.errors.title)}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.scoring}
                          onChange={formik.handleChange}
                          // error={formik.touched.scoring && Boolean(formik.errors.scoring)}
                          name="scoring"
                        />
                      }
                      label={
                        <span
                          style={{
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {formik.values.scoring ? 'Linear' : 'Non-Linear'}
                        </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      name="price"
                      label="Price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      error={formik.touched.price && Boolean(formik.errors.price)}
                      helperText={formik.touched.price && formik.errors.price}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="sku"
                      name="sku"
                      label="SKU"
                      value={formik.values.sku}
                      onChange={formik.handleChange}
                      error={formik.touched.sku && Boolean(formik.errors.sku)}
                      helperText={formik.touched.sku && formik.errors.sku}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className="modal-body">
                {/* <TextField */}
                {/*  margin="normal" */}
                {/*  required */}
                {/*  fullWidth */}
                {/*  multiline */}
                {/*  rows={4} */}
                {/*  id="description" */}
                {/*  name="description" */}
                {/*  label="Description" */}
                {/*  value={formik.values.description} */}
                {/*  onChange={formik.handleChange} */}
                {/*  error={formik.touched.description && Boolean(formik.errors.description)} */}
                {/*  helperText={formik.touched.description && formik.errors.description} */}
                {/* /> */}

                <label>Description</label>
                <ReactQuill
                  ref={quillRef}
                  style={{ maxHeight: '400px', marginTop: '14px', borderRadius: '4px' }}
                  value={body}
                  modules={modules}
                  theme={theme}
                  onChange={setBody}
                  placeholder="Type here..."
                />
                <br />
                <label>Intro</label>
                <ReactQuill
                  ref={introQuillRef}
                  style={{ marginTop: '14px', borderRadius: '4px' }}
                  value={bodyIntro}
                  modules={introModules}
                  theme={theme}
                  onChange={setBodyIntro}
                  placeholder="Type here..."
                />
                <br />
                <label>Outro</label>
                <ReactQuill
                  ref={outroQuillRef}
                  style={{ marginTop: '14px', borderRadius: '4px' }}
                  value={bodyOutro}
                  modules={outroModules}
                  theme={theme}
                  onChange={setBodyOutro}
                  placeholder="Type here..."
                />

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                  className="fileDrop"
                >
                  <Grid item xs={12} sm={6}>
                    <div className="form-group">
                      <label htmlFor="file" className="label">
                        Cover Image*
                      </label>
                      <UploadComponent setFieldValue={formik.setFieldValue} />
                      <Typography>Recommended dimensions: 1200x1200</Typography>
                      {coverImg ? (
                        <>
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${workBook.file_cover}`}
                            alt=""
                            className="img-thumbnail mt-2"
                            height={100}
                            width={100}
                          />
                          <DeleteForeverIcon
                            style={{ cursor: 'pointer', position: 'absolute' }}
                            onClick={() => {
                              setCoverImg(false);
                              formik.setFieldValue('file_cover', []);
                            }}
                          />
                        </>
                      ) : formik.values.file_cover ? (
                        formik.values.file_cover.map((file, i) => (
                          <div key={i} style={{ position: 'relative', width: 'fit-content' }}>
                            <Thumb file={file} />
                            <DeleteForeverIcon
                              style={{ cursor: 'pointer', position: 'absolute' }}
                              onClick={() => {
                                formik.setFieldValue('file_cover', []);
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <div className="form-group">
                      <label htmlFor="file" className="label">
                        Header Image*
                      </label>

                      <UploadComponentHeader setFieldValue={formik.setFieldValue} />
                      <Typography>Recommended dimensions: 1200x500</Typography>
                      {headerImg ? (
                        <>
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${workBook.file_header}`}
                            alt=""
                            className="img-thumbnail mt-2"
                            height={100}
                            width={100}
                          />
                          <DeleteForeverIcon
                            style={{ cursor: 'pointer', position: 'absolute' }}
                            onClick={() => {
                              setHeaderImg(false);
                              formik.setFieldValue('file_header', []);
                            }}
                          />
                        </>
                      ) : formik.values.file_header ? (
                        formik.values.file_header.map((file, i) => (
                          <div key={i} style={{ position: 'relative', width: 'fit-content' }}>
                            <Thumb file={file} />
                            <DeleteForeverIcon
                              style={{ cursor: 'pointer', position: 'absolute' }}
                              onClick={() => {
                                formik.setFieldValue('file_header', []);
                              }}
                            />
                          </div>
                        ))
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorMain"
                          label="colorMain"
                          value={formik.values.colorMain}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorMain"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorMain}
                        />
                      </div>
                      <span>Main color</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorLightShade"
                          label="colorLightShade"
                          value={formik.values.colorLightShade}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorLightShade"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorLightShade}
                        />
                      </div>
                      <span>Light shade</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorDarkShade"
                          label="colorDarkShade"
                          value={formik.values.colorDarkShade}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorDarkShade"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorDarkShade}
                        />
                      </div>
                      <span>Dark shade</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <div className="colorPicker">
                      <div className="pickerWrap">
                        <input
                          type="color"
                          name="colorBackground"
                          label="colorBackground"
                          value={formik.values.colorBackground}
                          onChange={formik.handleChange}
                        />
                        <input
                          name="colorBackground"
                          className="pickerText"
                          onChange={formik.handleChange}
                          value={formik.values.colorBackground}
                        />
                      </div>
                      <span>Background color</span>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <Box className="modal-footer" style={{ width: 'inherit', maxHeight: 'inherit' }}>
                <Divider style={{ margin: '20px 0' }} />
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-end"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item align="right">
                    <Button variant="warning" outline onClick={close}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button className="btn primary" type="submit">
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default EditWorkbook;

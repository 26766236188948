import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Outlet, redirect, useParams } from 'react-router-dom';
import useUser from '../../Hooks/useUser';
import ImpersonateView from '../ImpersonateView';
import Loading from '../Loading';
import Menu from '../Menu';
import SideBar from '../SideBar';

import styles from './PageLayout.module.scss';
import ResponsiveDrawer from '../responsiveDrawer';
import AnnouncementBar from '../AnnouncementBar';
import NotificationList from '../Notification';
import AlertModal from '../AlertModal';
import BackdropLoader from '../BackdropLoader';
import useStore from '../../Hooks/useStore';
import useBackdrop from '../../Hooks/useBackdrop';
import { logger } from '../../utils/logger';

const PageLayout = () => {
  const { isLoading, impersonateEmail, setImpersonateEmail, userView } = useUser();
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const { page } = useParams();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.page_layout}>
      {impersonateEmail && (
        <ImpersonateView impersonateEmail={impersonateEmail} setImpersonationEmail={setImpersonateEmail} />
      )}
      {userView && showAnnouncement && <AnnouncementBar onClose={() => setShowAnnouncement((prev) => !prev)} />}
      <div className={styles.layout_container}>
        <div className={styles.page_sidebar}>
          <SideBar />
          <ResponsiveDrawer />
        </div>
        <div className={styles.page_container}>
          <div className={styles.page_content}>
            <Menu />
            <Outlet />
            <div className={styles.footer}>
              <span className={styles.copywrite}>
                {'© '}
                {new Date().getFullYear()} Pure Desire Ministries
              </span>
              {page !== 'library' && (
                <div className={styles.page_version}>
                  <span className="version-number">Version {process.env.REACT_APP_PD_VERSION}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationList />
      <AlertModal />
      <BackdropLoader />
    </div>
  );
};

export default PageLayout;

export const layoutLoader = async () => {
  useBackdrop.getState().showBackdrop();
  try {
    const {
      accessToken: { jwtToken }
    } = await Auth.currentSession();

    useStore.getState().setToken(jwtToken);
    await useStore.getState().fetchUser();
    useBackdrop.getState().hideBackdrop();
    return 'loaded';
  } catch (error) {
    logger.error('Error fetching session data', error);
    return redirect('/');
  }
};

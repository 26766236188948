import React, { Suspense, useMemo } from 'react';
import Loading from '../../components/Loading';
import AdminContextProvider from '../../Context/Admin';
import useUser from '../../Hooks/useUser';
import TagManager from 'react-gtm-module';

const userDashboard = React.lazy(() => import('../User/dashboard'));

const adminDashboard = React.lazy(() => import('../Admin/dashboard'));

const Dashboard = () => {
  const { userView, currentUser } = useUser();

  if (window.location.hostname == 'app.puredesire.org') {
    if (userView) {
      const tagManagerArgs = {
        gtmId: 'G-VX6JMGWL5Q'
      };

      TagManager.initialize(tagManagerArgs);
    }
  }

  window.onerror = function (event, url, line, colno, error) {
    gtag('event', 'exception', {
      description: 'Error: ' + error + ', Url: ' + url + ', Line: ' + line,
      fatal: false // set to true if the error is fatal
    });
  };

  const Page = useMemo(() => {
    switch (true) {
      case currentUser?.role === 'admin' && !userView:
        return adminDashboard;
      case currentUser?.role === 'admin' && userView:
      case currentUser?.role === 'user':
      default:
        return userDashboard;
    }
  }, [currentUser?.role, userView]);

  return (
    <AdminContextProvider>
      <Suspense fallback={<Loading />}>
        <Page />
      </Suspense>
    </AdminContextProvider>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { MobileAdminToggle, MobileProfile, MobileSignOut } from './Menu';
import Link from '@mui/material/Link';
import { MainListItems } from '../views/Dashboard/listItems';
import menuIcon from '../images/icons/Icon.svg';
import logo from '../images/pure-desire-logo-2022.png';
import LanguageSelect from '../languageSelect';
import useUser from './../Hooks/useUser';

const drawerWidth = 340;

// interface Props {
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window?: () => Window;
// }

const ResponsiveDrawer = (props) => {
  const { window } = props;
  const { userView } = useUser();

  //const [userView, setUserView] = useLocalStorage('UserView', true);
  // const [userView, setUserView] = React.useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const MobileDrawer = () => {
    return (
      <div className="navStyles">
        <MainListItems handleDrawerToggle={handleDrawerToggle} userView={userView} />

        <ul className="userToolbar">
          <MobileProfile />
          <MobileAdminToggle />
          <li key={'language'}>
            <LanguageSelect />
          </li>
          <MobileSignOut />
        </ul>

        <div className="navCta">
          <h2>Have questions?</h2>
          <p>Contact us if you need assistance.</p>
          <Link href="#" sx={{ mt: 1, mb: 7 }} style={{ display: 'block', textAlign: 'center' }}>
            Contact Us
          </Link>
        </div>
      </div>
    );
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar className="mobileToolbar showMobile">
          <header className="mobileHeader">
            <a href="/">
              <img src={logo} alt="Pure Desire Ministries" className="drawerLogo" />
            </a>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <img src={menuIcon} alt="Pure Desire Ministries" />
            </IconButton>
          </header>
        </Toolbar>
      </AppBar>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className="mobileDrawer"
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        <MobileDrawer />
      </Drawer>
    </Box>
  );
};

export default ResponsiveDrawer;

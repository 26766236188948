import React, { useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import Modal from '../../Modal';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMutation, useQueryClient } from 'react-query';
import useQuill from '../../../Hooks/useQuill';
import { postDataAPI, putDataAPI } from '../../../Helpers/FetchAPI';

const CreateLesson = ({ workBookId, sectionId, open, titleInfo }) => {
  const { ref: quillRef, adminModules: modules } = useQuill();
  const queryClient = useQueryClient();
  const [body, setBody] = useState('');

  const [isSaving, setIsSaving] = useState(null);

  const validationSchema = Yup.object().shape({
    lessonTitle: Yup.string().required('Title is required')
  });

  const addWorkBookItemMutation = useMutation(
    async (params) => {
      if (isSaving) {
        return await putDataAPI(`/workbookItems/item/${isSaving._id}`, params);
      }
      return await postDataAPI('/workbookItems/item/create/', params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');

        open();
        setBody('');
        setIsSaving(null);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const saveWorkBookItemMutation = useMutation(
    async (params) => {
      if (isSaving) {
        return await putDataAPI(`/workbookItems/item/${isSaving._id}`, params);
      }
      return await postDataAPI('/workbookItems/item/create/', params);
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        setIsSaving(data.result);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      lessonTitle: '',
      lessonDescription: '',
      type: 'Content'
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (fields) => {
      const params = {
        title: fields.lessonTitle,
        description: fields.lessonDescription,
        content: body,
        type: fields.type,
        workbookid: workBookId,
        sectionid: sectionId
      };
      addWorkBookItemMutation.mutate(params);
    }
  });

  const handleSaving = () => {
    const fields = formik.values;
    const params = {
      title: fields.lessonTitle,
      description: fields.lessonDescription,
      content: body,
      type: fields.type,
      workbookid: workBookId,
      sectionid: sectionId
    };

    saveWorkBookItemMutation.mutate(params);
  };

  return (
    <React.StrictMode>
      <Modal variant="medium">
        <div
          style={{
            overflowY: 'auto',
            height: '100%',
            width: '100%'
          }}
        >
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Box className="modal-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
                <Box className="modal-header" style={{ maxHeight: 'inherit' }}>
                  <Typography variant="h2"> {titleInfo.title} </Typography>
                  <Typography variant="body1"> {titleInfo.description} </Typography>
                  <Divider style={{ margin: '20px 0' }} />
                  <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <label htmlFor="file" className="label">
                        Title*
                      </label>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="lessonTitle"
                        name="lessonTitle"
                        // label="Section Title"
                        placeholder="Type here..."
                        value={formik.values.lessonTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.lessonTitle && Boolean(formik.errors.lessonTitle)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <label htmlFor="file" className="label">
                        Description
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="lessonDescription"
                        name="lessonDescription"
                        // label="Section Description"
                        placeholder="Type here..."
                        value={formik.values.lessonDescription}
                        onChange={formik.handleChange}
                        error={formik.touched.lessonDescription && Boolean(formik.errors.lessonDescription)}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Divider style={{ margin: '20px 0' }} />

                <Box className="modal-body" style={{ width: '100%' }}>
                  <Grid style={{ marginTop: '20px' }}>
                    <label htmlFor="file" className="label">
                      Content*
                    </label>
                    <ReactQuill
                      ref={quillRef}
                      style={{ maxHeight: '400px', marginTop: '14px', borderRadius: '4px', width: '100%' }}
                      value={body}
                      modules={modules}
                      theme="snow"
                      onChange={setBody}
                      placeholder="Type here..."
                    />
                  </Grid>
                </Box>

                <Box className="modal-footer" style={{ maxHeight: 'inherit', width: '100%' }}>
                  <Divider style={{ margin: '20px 0' }} />
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-end"
                    style={{ marginTop: '0' }}
                    alignItems="center"
                  >
                    <Grid item align="right">
                      <Button className="buttonLink outline red" onClick={open}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item align="center">
                      <Button className="buttonLink outline" type="button" onClick={handleSaving}>
                        Save
                      </Button>
                    </Grid>
                    <Grid item align="right">
                      <Button className="buttonLink" type="submit">
                        Save and Exit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </React.StrictMode>
  );
};

export default CreateLesson;

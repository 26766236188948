/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import RenderString from '../RenderString';
import SubTitle from '../SubTitle';
import Title from '../Title';
import { SubmitListener } from '../SubmitListener';

import styles from './WorkbookItem.module.scss';

const WorkbookItem = ({
  title,
  description,
  type,
  content,
  questions,
  formik,
  userWorkbookItem,
  handleSubmitWithoutNext,
  errorQuestions,
  setSaving
}) => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (formik) {
        if (userWorkbookItem && Object.keys(userWorkbookItem).length === 0) {
          setInitialValues({});
          return;
        }

        if (type === 'Assessment') {
          const initValues = {};
          questions.forEach((question, i) => {
            const name = `${question.type}${i}`;
            let value = '';
            const answers = userWorkbookItem?.questions?.find((item) => item.question_id === question._id)?.answers;
            switch (question.type) {
              case 'text':
              case 'textarea':
              case 'radio':
              case 'checkbox':
                formik.setFieldValue(name, answers?.[0]?.content);
                value = answers?.[0]?.content || '';
                break;
              case 'rating':
                formik.setFieldValue(name, parseInt(answers?.[0]?.content, 10));
                value = parseInt(answers?.[0]?.content, 10) || 0;
                break;
              default:
                break;
            }

            initValues[name] = value;
          });
          setInitialValues(initValues);
        }
      }
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, type, userWorkbookItem]);

  switch (type) {
    case 'Lesson':
    case 'Content':
      return (
        <div className={`${styles.workbookitem} ${styles.content}`}>
          <SubTitle>{title}</SubTitle>
          <div className={styles.workbookitem_description}>{description}</div>
          <RenderString content={content} />
        </div>
      );
    case 'Assessment':
      if (formik) {
        return (
          <div className={`${styles.workbookitem} ${styles.assessment}`}>
            {userWorkbookItem && (
              <SubmitListener
                formik={formik}
                initialValues={initialValues}
                handleSubmitWithoutNext={handleSubmitWithoutNext}
                setSaving={setSaving}
              />
            )}
            <SubTitle>{title}</SubTitle>
            {questions.map((question, i) => {
              const marks = [
                {
                  value: 1,
                  label: question.descLow
                },
                {
                  value: 10,
                  label: question.descHigh
                }
              ];
              const name = question.type + i;
              if (question.type === 'text') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <div className={`${styles.title} ${errorQuestions.includes(name) && styles.errorQuestion}`}>
                        {question.title}
                      </div>
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      placeholder="Type here..."
                      sx={{
                        fontSize: '1.6rem',
                        fontFamily: 'inherit'
                      }}
                      id={name}
                      name={name}
                      value={formik.values[name]}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setSaving({
                          message: 'Saving...',
                          disabled: true
                        });
                      }}
                    />
                  </div>
                );
              }
              if (question.type === 'textarea') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <div className={`${styles.title} ${errorQuestions.includes(name) && styles.errorQuestion}`}>
                        {question.title}
                      </div>
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={4}
                      placeholder="Type here..."
                      sx={{
                        fontSize: '1.6rem',
                        fontFamily: 'inherit'
                      }}
                      id={name}
                      name={name}
                      value={formik.values[name]}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setSaving({
                          message: 'Saving...',
                          disabled: true
                        });
                      }}
                    />
                  </div>
                );
              }
              if (question.type === 'content') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <RenderString content={question.title} />
                    </div>
                  </div>
                );
              }
              if (question.type === 'rating') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <div className={`${styles.title} ${errorQuestions.includes(name) && styles.errorQuestion}`}>
                        {question.title}
                      </div>
                    </div>
                    <Slider
                      valueLabelDisplay="auto"
                      step={1}
                      min={1}
                      max={10}
                      marks={marks}
                      sx={{
                        width: '50%',
                        marginLeft: '2rem'
                      }}
                      id={name}
                      name={name}
                      value={formik.values[name] || 1}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setSaving({
                          message: 'Saving...',
                          disabled: true
                        });
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <Typography
                      sx={{ fontWeight: '400', fontSize: '15px' }}
                      style={question.scaleDesc !== '' ? { display: 'block' } : { display: 'none' }}
                    >
                      {question.scaleDesc}
                    </Typography>
                  </div>
                );
              }

              if (question.type === 'radio') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <div className={`${styles.title} ${errorQuestions.includes(name) && styles.errorQuestion}`}>
                        {question.title}
                      </div>
                    </div>
                    <FormControl sx={{ marginLeft: '2rem' }}>
                      <RadioGroup
                        aria-labelledby="assessment-radio-buttons-group-label"
                        id={name}
                        name={name}
                        value={formik.values[name] || ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setSaving({
                            message: 'Saving...',
                            disabled: true
                          });
                        }}
                        onBlur={formik.handleBlur}
                      >
                        {!!question.options &&
                          question.options.map((option) => (
                            <FormControlLabel
                              control={<Radio />}
                              label={option.title}
                              value={option.title}
                              key={option._id}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                );
              }

              if (question.type === 'checkbox') {
                return (
                  <div className={styles.assessment_order} key={question._id}>
                    <div className={styles.question}>
                      <div className={`${styles.title} ${errorQuestions.includes(name) && styles.errorQuestion}`}>
                        {question.title}
                      </div>
                    </div>
                    <FormControl
                      sx={{ marginLeft: '2rem' }}
                      component="fieldset"
                      variant="standard"
                      id={name}
                      name={name}
                      value={formik.values[name] || []}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setSaving({
                          message: 'Saving...',
                          disabled: true
                        });
                      }}
                      onBlur={formik.handleBlur}
                    >
                      {!!question.options &&
                        question.options.map((option) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={name}
                                  checked={!!formik.values[name]?.find((item) => item === option.title)}
                                  defaultChecked={false}
                                />
                              }
                              label={option.title}
                              value={option.title}
                              key={option._id}
                            />
                          );
                        })}
                    </FormControl>
                  </div>
                );
              }

              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      return (
        <div className={`${styles.workbookitem} ${styles.assessment}`}>
          <Title>{title}</Title>
          {questions.map((question, i) => {
            const marks = [
              {
                value: 1,
                label: question.descLow
              },
              {
                value: 10,
                label: question.descHigh
              }
            ];
            const name = question.type + i;
            if (question.type === 'text') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                  <TextField
                    margin="normal"
                    fullWidth
                    placeholder="Type here..."
                    sx={{
                      fontSize: '1.6rem',
                      fontFamily: 'inherit'
                    }}
                    id={name}
                    name={name}
                    value=""
                  />
                </div>
              );
            }
            if (question.type === 'textarea') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                  <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Type here..."
                    sx={{
                      fontSize: '1.6rem',
                      fontFamily: 'inherit'
                    }}
                    id={name}
                    name={name}
                    value=""
                  />
                </div>
              );
            }
            if (question.type === 'content') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <RenderString content={question.title} />
                  </div>
                </div>
              );
            }
            if (question.type === 'rating') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                  <Slider
                    valueLabelDisplay="auto"
                    step={1}
                    min={1}
                    max={10}
                    marks={marks}
                    sx={{
                      width: '50%',
                      marginLeft: '2rem'
                    }}
                    id={name}
                    name={name}
                    value=""
                  />
                  <Typography
                    sx={{ fontWeight: '400', fontSize: '15px' }}
                    style={question.scaleDesc !== '' ? { display: 'block' } : { display: 'none' }}
                  >
                    {question.scaleDesc}
                  </Typography>
                </div>
              );
            }

            if (question.type === 'radio') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                  <FormControl sx={{ marginLeft: '2rem' }}>
                    <RadioGroup aria-labelledby="assessment-radio-buttons-group-label" id={name} name={name} value="">
                      {!!question.options &&
                        question.options.map((option) => (
                          <FormControlLabel
                            control={<Radio name={name} />}
                            label={option.title}
                            value={option.title}
                            key={option._id}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              );
            }

            if (question.type === 'checkbox') {
              return (
                <div className={styles.assessment_order} key={question._id}>
                  <div className={styles.question}>
                    <div className={styles.title}>{question.title}</div>
                  </div>
                  <FormControl
                    sx={{ marginLeft: '2rem' }}
                    component="fieldset"
                    variant="standard"
                    id={name}
                    name={name}
                    value={[]}
                  >
                    {!!question.options &&
                      question.options.map((option, index) => (
                        <FormControlLabel
                          control={<Checkbox name={name + index} defaultChecked={false} />}
                          label={option.title}
                          value={option.title}
                          key={option._id}
                        />
                      ))}
                  </FormControl>
                </div>
              );
            }

            return (
              <div className={styles.assessment_order} key={question._id}>
                <div className={styles.question}>
                  <div className={styles.title}>{question.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
  }
};

WorkbookItem.defaultProps = {
  userWorkbookItem: null,
  errorQuestions: []
};

export default WorkbookItem;

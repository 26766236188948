import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { deleteDataAPI, getDataAPI, postDataAPI, putDataAPI } from '../../Helpers/FetchAPI';
import useUser from '../../Hooks/useUser';
import { logger } from '../../utils/logger';

const initState = {
  title: '',
  description: '',
  requiredScore: 0,
  priority: 'low',
  questions: []
};

export const AdminAssessmentsContext = createContext({
  initState,
  assessmentsData: [],
  setAssessmentsData: () => {},
  isLoading: false,
  error: null,
  assessment: initState,
  setAssessment: () => {},
  handleAddAssessment: () => {},
  handleUpdateAssessment: () => {},
  handleDeleteAssessment: () => {}
});

const AdminAssessmentsContextProvider = ({ children }) => {
  const { currentUser } = useUser();
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [assessment, setAssessment] = useState(initState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAssessments = useCallback(async () => {
    await getDataAPI('/api/assessments/publish')
      .then((res) => {
        setAssessmentsData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logger.error('Error getting assessments', err);
        setError(err);
        setIsLoading(false);
      });
  }, [currentUser]);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (currentUser) {
        setIsLoading(true);
        getAssessments();
      }
    }

    return () => {
      isActive = false;
    };
  }, [currentUser, getAssessments]);

  const handleAddAssessment = useCallback(
    async (status, callback) => {
      await postDataAPI('/api/assessments/create', {
        ...assessment,
        status,
        userId: currentUser._id
      }).then((res) => {
        const newAssessment = res.data.data;

        setAssessmentsData((prev) => [...prev, { ...newAssessment, ...assessment }]);
        setAssessment(initState);
        if (callback) {
          callback();
        }
      });
    },
    [assessment, currentUser]
  );

  const handleUpdateAssessment = useCallback(
    async (status, callback) => {
      await putDataAPI(`/api/assessments/${assessment._id}`, { ...assessment, status }).then((res) => {
        const { success } = res.data;
        if (success) {
          setAssessmentsData((prev) => {
            const updatedAssessments = prev.map((item) => {
              if (item._id === assessment._id) {
                return { ...item, ...assessment, status };
              }

              return item;
            });

            return updatedAssessments;
          });

          if (callback) {
            callback();
          }
        }
      });
    },
    [assessment]
  );

  const handleDeleteAssessment = useCallback(async (id) => {
    await deleteDataAPI(`/api/assessments/${id}`).then((res) => {
      const { success } = res.data;

      if (success) {
        setAssessmentsData((prev) => [...prev.filter((item) => item._id !== id)]);
      }
    });
  }, []);

  const value = useMemo(() => {
    return {
      initState,
      assessmentsData,
      setAssessmentsData,
      isLoading,
      error,
      assessment,
      setAssessment,
      handleAddAssessment,
      handleUpdateAssessment,
      handleDeleteAssessment
    };
  }, [
    assessment,
    assessmentsData,
    error,
    handleAddAssessment,
    handleDeleteAssessment,
    handleUpdateAssessment,
    isLoading
  ]);
  return <AdminAssessmentsContext.Provider value={value}>{children}</AdminAssessmentsContext.Provider>;
};

export default AdminAssessmentsContextProvider;

import { create } from 'zustand';
import { getDataAPI, postDataAPI } from '../Helpers/FetchAPI';
import { queryClient } from '../lib/store';
import { logger } from '../utils/logger';

export const addressInit = {
  name: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

const useStore = create((set, get) => ({
  initialized: false,
  token: null,
  user: null,
  isAuthenticated: false,
  billingAddress: { ...addressInit },
  shippingAddress: { ...addressInit },
  verifiedBilling: false,
  verifiedShipping: false,
  coupons: [],
  addCoupon: (coupon) => {
    set((state) => {
      const couponExists = state.coupons.some((existingCoupon) => existingCoupon.id === coupon.id);
      if (!couponExists) {
        return { coupons: [...state.coupons, coupon] };
      }
      return state;
    });
  },
  removeCoupon: (couponId) => {
    set((state) => ({
      coupons: state.coupons.filter((coupon) => coupon.id !== couponId)
    }));
  },

  autoApplyCoupon: async (userId) => {
    try {
      const res = await postDataAPI('/api/coupon/auto-apply', {
        userId
      });

      if (res.data?.coupon) {
        get().addCoupon(res.data.coupon);
        set({ initialized: true });
      }
    } catch (err) {
      logger.error('Error applying coupon', err);
    }
  },

  setToken: (token) => {
    set({ token, isAuthenticated: true });
  },

  fetchUser: async () => {
    const cachedUser = queryClient.getQueryData('authenticatedUser'); // Check if user data is already cached
    if (cachedUser) {
      get().setUser(cachedUser);
    } else {
      const user = res.data;
      try {
        await getDataAPI('/user/profile');
        get().setUser(user);
        queryClient.setQueryData('authenticatedUser', user);

        return user;
      } catch (error) {
        logger.error('Error getting user: %s', user, error);
      }
    }
  },

  setUser: (user) => {
    set({ user });

    if (user) {
      if (!get().initialized) {
        get().autoApplyCoupon(user._id);
      }
    }
  },

  setBillingAddress: (address) => {
    set({ billingAddress: address });
    if (address?.shippoId) {
      get().setVerifiedBilling(true);
    }
  },
  setShippingAddress: (address) => {
    set({ shippingAddress: address });
    if (address?.shippoId) {
      get().setVerifiedShipping(true);
    }
  },
  setVerifiedBilling: (verified) => set({ verifiedBilling: verified }),
  setVerifiedShipping: (verified) => set({ verifiedShipping: verified })
}));

export default useStore;

import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import AssessmentContent2 from './AssessmentContent2';
import AssessmentContent1 from './AssessmentContent1';
import AssessmentContent3 from './AssessmentContent3';
import Modal from '../../../Modal';
import { postDataAPI, putDataAPI } from '../../../../Helpers/FetchAPI';

const CreateAssessment = ({ workBookId, sectionId, open, titleInfo }) => {
  const queryClient = useQueryClient();
  const [elements, setElements] = useState([]);
  const [errorContents, setErrorContents] = useState([]);
  const [isSaving, setIsSaving] = useState(null);

  const addWorkBookItemMutation = useMutation(
    async (params) => {
      if (isSaving) {
        return await putDataAPI(`/workbookItems/item/${isSaving._id}`, params);
      }
      return await postDataAPI('/workbookItems/item/create/', params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        open();
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  const saveWorkBookItemMutation = useMutation(
    async (params) => {
      if (isSaving) {
        return await putDataAPI(`/workbookItems/item/${isSaving._id}`, params);
      }
      return postDataAPI('/workbookItems/item/create/', params);
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries('sectionItems');
        queryClient.invalidateQueries('bookSection');
        setIsSaving(data.result);
      },
      onError: (err) => {
        alert(err.message);
      }
    }
  );

  function addItem(type) {
    const key = Date.now();

    switch (type) {
      case 'text':
        setElements([...elements, { type: 'text', options: [], key }]);
        break;
      case 'textarea':
        setElements([...elements, { type: 'textarea', options: [], key }]);
        break;
      case 'rating':
        setElements([...elements, { type: 'rating', options: [], key }]);
        break;
      case 'checkbox':
        setElements([...elements, { type: 'checkbox', options: [], key }]);
        break;
      case 'radio':
        setElements([...elements, { type: 'radio', options: [], key }]);
        break;
      case 'content':
        setElements([...elements, { type: 'content', options: [], key }]);
        break;
    }
  }

  const handleRemove = (i) => {
    setElements((elements) => elements.filter((e, index) => index !== i));
  };

  const handleRemoveSubItem = (i, j) => {
    setElements((elements) =>
      elements.map((e, index) => {
        if (index === i) {
          e.options = e.options.filter((item, order) => order !== j);
          return e;
        }
        return e;
      })
    );
  };

  const handleAddSubItem = (i) => {
    setElements((elements) =>
      elements.map((e, index) => {
        const key = Date.now();
        if (index === i) {
          e.options.push({ value: 'item', key });
          return e;
        }
        return e;
      })
    );
  };

  const addContent = (cont, i) => {
    setElements((elements) =>
      elements.map((e, index) => {
        if (index === i) {
          e.options[0] = cont;
          return e;
        }
        return e;
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      assessmentTitle: '',
      requiredScore: 1,
      type: 'Assessment'
    },
    enableReinitialize: true,
    validationSchema: '',
    onSubmit: (fields) => {
      // Check content validation
      const checkContent = elements.some((el) => el.type === 'content');
      if (checkContent) {
        const filteredElement = elements.filter((el) => el.type === 'content');
        if (filteredElement.some((el) => el.options.length === 0)) {
          setErrorContents(filteredElement.filter((el) => el.options.length === 0));
          return;
        }
        setErrorContents([]);
      }

      const quesArray = [];
      elements.map((item) => {
        if (item.type === 'checkbox' || item.type === 'radio') {
          const optionArr = [];
          item.options.map((option) => {
            optionArr.push({
              title: fields[`${item.key}options${option.key}`],
              points: fields[`${item.key}points${option.key}`]
            });
          });

          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            options: optionArr
          });
        } else if (item.type === 'content') {
          quesArray.push({
            title: item.options[0] ? item.options[0] : '',
            type: item.type
          });
        } else if (item.type === 'rating') {
          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
            scaleDesc: fields[`scaleDesc${item.key}`] ? fields[`scaleDesc${item.key}`] : '',
            descHigh: fields[`highScale${item.key}`] ? fields[`highScale${item.key}`] : '',
            descLow: fields[`lowScale${item.key}`] ? fields[`lowScale${item.key}`] : ''
          });
        } else {
          quesArray.push({
            title: fields[`${item.type}${item.key}`],
            type: item.type,
            points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1
          });
        }
      });

      const params = {
        title: fields.assessmentTitle,
        type: fields.type,
        required_score: parseInt(fields.requiredScore),
        workbookid: workBookId,
        sectionid: sectionId,
        questions: quesArray
      };

      addWorkBookItemMutation.mutate(params);
      // resetForm()
    }
  });

  const handleSaving = () => {
    const fields = formik.values;
    // Check content validation
    const checkContent = elements.some((el) => el.type === 'content');
    if (checkContent) {
      const filteredElement = elements.filter((el) => el.type === 'content');
      if (filteredElement.some((el) => el.options.length === 0)) {
        setErrorContents(filteredElement.filter((el) => el.options.length === 0));
        return;
      }
      setErrorContents([]);
    }

    const quesArray = [];
    elements.map((item) => {
      if (item.type === 'checkbox' || item.type === 'radio') {
        const optionArr = [];
        item.options.map((option) => {
          optionArr.push({
            title: fields[`${item.key}options${option.key}`],
            points: fields[`${item.key}points${option.key}`]
          });
        });

        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          options: optionArr
        });
      } else if (item.type === 'content') {
        quesArray.push({
          title: item.options[0] ? item.options[0] : '',
          type: item.type
        });
      } else if (item.type === 'rating') {
        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1,
          scaleDesc: fields[`scaleDesc${item.key}`] ? fields[`scaleDesc${item.key}`] : '',
          descHigh: fields[`highScale${item.key}`] ? fields[`highScale${item.key}`] : '',
          descLow: fields[`lowScale${item.key}`] ? fields[`lowScale${item.key}`] : ''
        });
      } else {
        quesArray.push({
          title: fields[`${item.type}${item.key}`],
          type: item.type,
          points: fields[`points${item.key}`] ? parseInt(fields[`points${item.key}`]) : 1
        });
      }
    });

    const params = {
      title: fields.assessmentTitle,
      type: fields.type,
      required_score: parseInt(fields.requiredScore),
      workbookid: workBookId,
      sectionid: sectionId,
      questions: quesArray
    };

    saveWorkBookItemMutation.mutate(params);
  };

  const handleDragandDrop = (result) => {
    if (!result.destination) return;

    setElements((elements) => {
      const [removed] = elements.splice(result.source.index, 1);
      elements.splice(result.destination.index, 0, removed);
      return elements;
    });
  };

  return (
    <Modal variant="large">
      <div
        style={{
          height: '100%',
          maxWidth: '100%'
        }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Divider style={{ margin: '20px 0' }} />
            <Box className="modal-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
              <Box className="modal-header" style={{ maxHeight: 'inherit' }}>
                <Typography variant="h2"> {titleInfo.title} </Typography>
                <Typography variant="body1"> {titleInfo.description} </Typography>
                <Divider style={{ margin: '20px 0' }} />
              </Box>
              <Box
                className="modal-body"
                style={{
                  overflowY: 'auto',
                  maxHeight: '400px',
                  overflowX: 'auto',
                  padding: '0px',
                  width: '100%',
                  height: '50%'
                }}
              >
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={8} align="left" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Title</label>
                    <TextField
                      margin="normal"
                      required
                      id="assessmentTitle"
                      name="assessmentTitle"
                      placeholder="Type here..."
                      value={formik.values.assessmentTitle}
                      onChange={formik.handleChange}
                      error={formik.touched.assessmentTitle && Boolean(formik.errors.assessmentTitle)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} align="left" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Minimum score needed to pass</label>
                    <TextField
                      type="number"
                      margin="normal"
                      required
                      id="requiredScore"
                      name="requiredScore"
                      // label="Section Title"
                      placeholder="1"
                      value={formik.values.requiredScore}
                      onChange={formik.handleChange}
                      error={formik.touched.requiredScore && Boolean(formik.errors.requiredScore)}
                    />
                  </Grid>
                </Grid>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h2">Content</Typography>
                <DragDropContext onDragEnd={handleDragandDrop}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {elements.map((e, i) => (
                          <Draggable key={`item-${e.key}`} draggableId={`item-${e.key}`} index={i}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {e.type === 'text' || e.type === 'textarea' || e.type === 'rating' ? (
                                  <AssessmentContent1
                                    key={e.key}
                                    item={e}
                                    index={i}
                                    removeItem={() => handleRemove(i)}
                                    formik={formik}
                                  />
                                ) : e.type === 'content' ? (
                                  <AssessmentContent3
                                    key={e.key}
                                    item={e}
                                    addContent={(e) => addContent(e, i)}
                                    removeItem={() => handleRemove(i)}
                                    errorContents={errorContents}
                                  />
                                ) : (
                                  <AssessmentContent2
                                    key={e.key}
                                    item={e}
                                    index={i}
                                    formik={formik}
                                    removeItem={() => handleRemove(i)}
                                    addSubItem={() => handleAddSubItem(i)}
                                    removeSubitem={(j) => handleRemoveSubItem(i, j)}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Box
                  className="dashed-border"
                  sx={{
                    minHeight: '180px',
                    borderRadius: '4px',
                    margin: '20px 0',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '30px'
                  }}
                >
                  <Grid container spacing={10} direction="row" justifyContent="center" alignItems="center">
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('content')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Content
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('text')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Text Input
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('textarea')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Add Text Area
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('rating')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Rating(1-10)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('checkbox')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Checkboxes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="o_icon_wrapper" onClick={() => addItem('radio')}>
                        <AddIcon style={{ fill: '#FFA659' }} />
                      </div>
                      <Typography style={{ color: '#00000099', fontSize: '15px', paddingTop: '5px' }}>
                        Radio Buttons
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <div />
              </Box>
              <Box className="modal-footer" style={{ width: '100%', maxHeight: 'inherit', minHeight: '20px' }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-end"
                  style={{ marginTop: '0' }}
                  alignItems="center"
                >
                  <Grid item align="right">
                    <Button className="buttonLink outline red" onClick={open}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button className="buttonLink outline" type="button" onClick={handleSaving}>
                      Save
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button className="buttonLink" type="submit">
                      Save and Exit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default CreateAssessment;

import React, { createContext, useCallback, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { useQuery } from 'react-query'; // Import useQuery
import { getDataAPI, putDataAPI } from '../../Helpers/FetchAPI';
import { logger } from '../../utils/logger';

export const AuthContext = createContext({
  userData: null,
  error: null,
  isLoading: false,
  isSuccess: false,
  signOut: () => {},
  getUser: () => {},
  refetch: () => {}
});

const AuthContextProvider = ({ children }) => {
  const getUser = useCallback(async (email) => {
    const res = await getDataAPI(`/user/get-user-information/${email}`);
    return res.data;
  }, []);

  const getAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { email } = user.attributes;
      const lastLogin = moment.unix(user.signInUserSession.accessToken.payload.auth_time);
      const userData = await getUser(email);

      if (userData.lastLogin !== lastLogin) {
        await putDataAPI(`/user/logs-user/${email}`, {
          lastLogin,
          awsUserId: user.username
        });
      }

      return userData;
    } catch (error) {
      logger.error('Error getting authenticated user', error);
      throw error;
    }
  }, [getUser]);

  const { data: userData, error, isLoading, refetch, isSuccess } = useQuery('authenticatedUser', getAuthenticatedUser);

  const signOut = useCallback(async (global = false) => {
    await Auth.signOut({ global });
    window.location.reload('/');
  }, []);

  const value = useMemo(
    () => ({
      userData,
      error,
      isLoading,
      signOut,
      getUser,
      refetch,
      isSuccess
    }),
    [userData, error, isLoading, signOut, getUser, refetch, isSuccess]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

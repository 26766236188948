import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Divider, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import MdVisibility from '@mui/icons-material/Visibility';
import MdVisibilityOff from '@mui/icons-material/VisibilityOff';
import { mainTheme } from '../../mainTheme';
import { federatedSignIn, signUp } from '../../utils/cognitoAuth';
import OtherButton from '../../components/Button';
import { ReactComponent as GoogleIcon } from '../../images/icons/google.svg';
import { logger } from '../../utils/logger';
import LoadingButton from '@mui/lab/LoadingButton';

const SignUp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get('email');

  const [registerEmail, setRegisterEmail] = useState(userEmail ? userEmail : '');
  const [registerPassword, setRegisterPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // if(userEmail){
  //   setRegisterEmail(userEmail);
  // }

  function handleRegister() {
    if (registerEmail && registerPassword) {
      setIsSubmitting(true);
      signUp(registerEmail.trim(), registerPassword.trim())
        .then(() => {
          navigate('/?message=Please%20activate%20your%20account');
        })
        .catch((err) => {
          logger.error('Error signing up user', registerEmail, err);
          setErrorMessage(err.message);
          if (err.code === 'UsernameExistsException') {
            navigate('/');
          }
        })
        .finally(() => setTimeout(() => setIsSubmitting(false), 500));
    } else {
      setErrorMessage('Email and Password fields are required.');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleRegister();
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}
        >
          <Typography color="primary.neutral" component="h1" variant="h1" sx={{ mb: 2 }}>
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ width: '90%' }}>
            {errorMessage && (
              <p className="error" style={{ fontWeight: 'bold', color: 'red', fontSize: '18px' }}>
                {' '}
                {errorMessage}{' '}
              </p>
            )}

            <div className="signin_form">
              <div className="label_textfield">
                <span>Email Address*</span>

                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setRegisterEmail(e.target.value.toLowerCase())}
                  value={registerEmail}
                  inputProps={{ className: 'textField' }}
                />
              </div>
              <div className="label_textfield">
                <span>Password*</span>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setRegisterPassword(e.target.value)}
                  value={registerPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <MdVisibility className="visible" /> : <MdVisibilityOff className="visible" />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className="signin_buttons">
              <div>
                <LoadingButton type="submit" variant="contained" sx={{ mt: 1 }} loading={isSubmitting}>
                  Sign Up
                </LoadingButton>
              </div>
              <Divider>Or</Divider>
              <div className="signin_providers">
                <Typography component="p" variant="p">
                  Sign In Using
                </Typography>
                <OtherButton onClick={() => federatedSignIn('Google')} variant="primary" className="button_google">
                  <GoogleIcon />
                </OtherButton>
              </div>
              <Link href="/" variant="body2" sx={{ mt: 4 }} style={{ textAlign: 'right' }}>
                Already have an account? Sign In
              </Link>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;

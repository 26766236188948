import React, { useMemo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { capitalize } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import styles from '../../components/SideBar/SideBar.module.scss';
import { useTranslation } from 'react-i18next';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CampaignIcon from '@mui/icons-material/Campaign';
import DiscountIcon from '@mui/icons-material/Discount';
import ShopIcon from '@mui/icons-material/Shop';
import BusinessIcon from '@mui/icons-material/Business';

import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

export const MainListItems = ({ handleDrawerToggle, userView }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const userSidebar = useMemo(() => {
    return [
      {
        name: t('dashboard'),
        path: '/',
        icon: HomeIcon
      },
      {
        name: t('library'),
        path: '/library',
        icon: LibraryBooksIcon
      },
      // {
      //   name: 'Recent Purchases',
      //   path: '/recent-purchases',
      //   icon: HistoryIcon
      // },
      {
        name: t('credits'),
        path: '/credits',
        icon: LocalOfferIcon
      },
      // {
      //   name: t('your_groups'),
      //   path: '/groups',
      //   icon: PeopleAltOutlinedIcon
      // },
      // {
      //   name: 'Give',
      //   path: '/give',
      //   icon: VolunteerActivismIcon
      // },
      {
        name: t('notebooks'),
        path: '/notebooks',
        icon: EditIcon
      },
      {
        name: t('tools'),
        path: '/tools',
        icon: AssignmentOutlinedIcon
      }
      // {
      //   name: t('shop'),
      //   path: '/shop',
      //   icon: ShopIcon
      // }
    ];
  }, [t]);

  const adminSidebar = useMemo(() => {
    return [
      {
        name: t('dashboard'),
        path: '/',
        icon: HomeIcon
      },
      {
        name: t('library'),
        path: '/workbook',
        icon: HorizontalSplitIcon
      },
      {
        name: t('tools'),
        path: '/tools',
        icon: AssignmentOutlinedIcon
      },
      {
        name: t('users'),
        path: '/users',
        icon: PersonOutlineIcon
      },
      {
        name: t('groups'),
        path: '/groups',
        icon: PeopleAltOutlinedIcon
      },
      {
        name: t('announcement'),
        path: '/announcement',
        icon: CampaignIcon
      },
      {
        name: t('products'),
        path: '/products',
        icon: ShopIcon
      },
      {
        name: t('Funded Projects'),
        path: '/funded-projects',
        icon: BusinessIcon
      },
      {
        name: t('donations'),
        path: '/donations',
        icon: VolunteerActivismIcon
      },
      {
        name: t('orders'),
        path: '/orders',
        icon: ShoppingBagIcon
      },
      {
        name: t('coupons'),
        path: '/coupons',
        icon: DiscountIcon
      },
      {
        name: t('Site Settings'),
        path: '/site-settings',
        icon: SettingsApplicationsIcon
      }
      // {
      //   name: t('disclaimers'),
      //   path: '/disclaimers',
      //   icon: ErrorIcon
      // }
    ];
  }, [t]);

  const user = userView ? userSidebar : adminSidebar;
  return (
    <>
      {user.map((item, i) => (
        <ListItemButton
          component={Link}
          to={item.path}
          onClick={() => {
            setTimeout(function () {
              handleDrawerToggle();
            }, 200);
          }}
          key={`item-${i}`}
          sx={{
            background: pathname === item.path ? 'linear-gradient(45deg, #FA9F57, #EA6442)' : 'transparent',
            borderRadius: '8px',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center'
          }}
          className={`${styles.menu_item} ${pathname === item.path && styles.active}`}
        >
          <div
            className={styles.menu_icon}
            style={{
              boxShadow: 'none !important',
              marginLeft: '1rem'
            }}
          >
            <item.icon />
          </div>
          <ListItemText primary={capitalize(item.name)} />
        </ListItemButton>
      ))}
    </>
  );
};

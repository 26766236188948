import React, { useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Divider, Grid, TextField } from '@mui/material';
import useStore from '../../../Hooks/useStore';
import { useQueryClient } from 'react-query';

import Title from '../../../components/Title';
import FundItem from '../../../components/FundItem';
import PDLink from '../../../components/PDLink';

import styles from './Checkout.module.scss';
import { formatCurrency } from '../../../Helpers/Functions';
import useUserCart from '../../../Hooks/useUserCart';
// import AddressVerification from '../../../components/AddressVerification';
import { useNavigate } from 'react-router-dom';
import { getDataAPI, postDataAPI } from '../../../Helpers/FetchAPI';
import Coupon from '../../../components/Coupon';
import useBackdrop from '../../../Hooks/useBackdrop';
import DigitalProcess from './PaymentProcess';
import useNotificationStore from '../../../Hooks/useNotification';
import useUserPayment from '../../../Hooks/useUserPayment';
import { logger } from '../../../utils/logger';

const Checkout = () => {
  const { billingAddress, shippingAddress, verifiedBilling, verifiedShipping, coupons, addCoupon, removeCoupon, user } =
    useStore();
  const { showBackdrop, hideBackdrop, isVisible } = useBackdrop();
  const {
    cart,
    total,
    subtotal,
    discount,
    handleReset,
    calculateDiscount,
    selectedShippingRate,
    isAllDigitalProduct,
    shipment,
    relatedProducts: suggested
  } = useUserCart();
  const { addNotification } = useNotificationStore();
  const {
    paymentMethodsData,
    isNewCard,
    setIsNewCard,
    handleDefaultPaymentMethod,
    removePaymentMethod,
    paymentMethodsLoading
  } = useUserPayment();
  const [saveCard, setSaveCard] = useState(false);

  const [inputCoupon, setInputCoupon] = useState('');
  const [errorCoupon, setErrorCoupon] = useState(null);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const defaultPaymentMethodId = paymentMethodsData?.defaultPaymentMethodId || '';

  const handleCoupon = async () => {
    showBackdrop();
    await getDataAPI(`/api/code-coupon/${inputCoupon}?userId=${user._id}`)
      .then((res) => {
        addCoupon(res.data.coupon);
        setInputCoupon('');
        hideBackdrop();
        addNotification({
          id: Math.random(),
          message: `${res.data.coupon?.name} coupon has been applied`,
          severity: 'success'
        });
      })
      .catch((err) => {
        logger.error('Error applying coupon', inputCoupon, user, err);
        setErrorCoupon(err.response?.data?.error || 'error');
        hideBackdrop();
      });
  };

  const handlePlaceOrder = async () => {
    showBackdrop("Please wait and don't close your browser...");
    setError(null);
    if (!verifiedBilling || !verifiedShipping) {
      hideBackdrop();
      return;
    }
    let params;
    if (!isNewCard && defaultPaymentMethodId !== '') {
      if (defaultPaymentMethodId === '') {
        setError('Please select a payment method to proceed with the order');
        hideBackdrop();
        return;
      }

      params = {
        shippingAddress,
        billingAddress,
        paymentMethodId: defaultPaymentMethodId,
        items: cart,
        total,
        email: user.email,
        coupons
      };
    } else {
      if (!stripe || !elements) {
        hideBackdrop();
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError?.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
        hideBackdrop();
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: billingAddress.name
          }
        }
      });

      if (error) {
        setError(error.message);
        hideBackdrop();
        return;
      }

      params = {
        shippingAddress,
        billingAddress,
        paymentMethodId: paymentMethod.id,
        items: cart,
        total,
        coupons,
        saveCard
      };
    }

    params.isAllDigitalProduct = isAllDigitalProduct;
    if (!isAllDigitalProduct) {
      params.selectedShippingRate = selectedShippingRate;
      params.shipment = shipment;
    }

    await postDataAPI('/api/place-order-with-payment', params)
      .then((res) => {
        hideBackdrop();
        handleReset();
        navigate(`/orders/${res.data.order?._id}`);
        queryClient.invalidateQueries('credits');
      })
      .catch((err) => {
        logger.error('Error creating cart', err);
        hideBackdrop();
        setError(err.response?.data?.error || 'error');
      });
    return;
  };

  return (
    <div className={styles.checkout}>
      <Title>Checkout</Title>
      <div className={styles.checkout_container}>
        <div className={styles.checkout_content}>
          <DigitalProcess
            isDigital={isAllDigitalProduct}
            paymentMethodsData={paymentMethodsData}
            paymentMethodsLoading={paymentMethodsLoading}
            isNewCard={isNewCard}
            setIsNewCard={setIsNewCard}
            handleDefaultPaymentMethod={handleDefaultPaymentMethod}
            removePaymentMethod={removePaymentMethod}
            saveCard={saveCard}
            setSaveCard={setSaveCard}
          />
        </div>
        <div className={styles.checkout_sidebar}>
          <div className={styles.payout}>
            <div className={styles.pay}>
              <span className={styles.checkout_title}>Cart</span>
              <Divider
                sx={{
                  marginBottom: '2rem'
                }}
              />
              {cart.map((item, i) => {
                const discountTotal = calculateDiscount(item, coupons);
                return (
                  <div className={styles.checkout_items} key={`checkout_${i}`}>
                    <span>
                      {item?.title}
                      {coupons.length > 0 ? `($${discountTotal} less)` : ''}
                    </span>
                    <span>{formatCurrency(item.price * item.quantity)}</span>
                  </div>
                );
              })}
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    placeholder="Enter Coupon"
                    value={inputCoupon}
                    onChange={(e) => setInputCoupon(e.target.value)}
                    error={!!errorCoupon}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button type="button" variant="contained" onClick={handleCoupon}>
                    Check Code
                  </Button>
                </Grid>

                {errorCoupon && (
                  <span style={{ color: 'red', marginTop: '1rem', padding: '0 1.75rem' }}>{errorCoupon}</span>
                )}
              </Grid>
              <Divider
                sx={{
                  marginTop: '2rem'
                }}
              />
              <div className={styles.checkout_cart}>
                <span>Subtotal: {formatCurrency(subtotal)}</span>
                {coupons.length > 0 && (
                  <Grid
                    xs={12}
                    item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem'
                    }}
                  >
                    {coupons.map((coupon) => {
                      return (
                        <Coupon
                          coupon={coupon}
                          selected
                          onRemoveCoupon={() => {
                            removeCoupon(coupon.id);
                            addNotification({
                              id: Math.random(),
                              message: `${coupon.name} coupon has been removed`,
                              severity: 'success'
                            });
                          }}
                          key={coupon.id}
                          variant="tiny"
                        />
                      );
                    })}
                  </Grid>
                )}
                <span>Discount: {formatCurrency(discount)}</span>
                <span>
                  Shipping: {selectedShippingRate ? formatCurrency(selectedShippingRate?.amount) : formatCurrency(0)}
                </span>
                <span className={styles.checkout_total}>Total: {formatCurrency(total)}</span>
              </div>
              <div className={styles.checkout_button}>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={PDLink}
                  path={{
                    to: '/shop'
                  }}
                >
                  Continue Shopping
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  type="button"
                  disabled={isVisible}
                  onClick={handlePlaceOrder}
                >
                  Place Order
                </Button>
              </div>
              <span style={{ color: 'red' }}>{error}</span>
            </div>
          </div>
        </div>
      </div>
      {suggested.length > 0 && (
        <div className={styles.checkout_product}>
          <span className={styles.checkout_title}>Suggested Products</span>
          <Divider />
          <div className={styles.checkout_project}>
            {suggested.map((item, i) => (
              <FundItem
                variant="ecommerce"
                title={item?.title}
                price={item?.price}
                src=""
                type={item?.type}
                key={`suggested_${i}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;

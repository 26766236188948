import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useMountedState } from 'react-use';
import { capitalize, Divider, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import MdVisibility from '@mui/icons-material/Visibility';
import MdVisibilityOff from '@mui/icons-material/VisibilityOff';
import Modal from '../../components/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import OtherButton from '../../components/Button';
import { ReactComponent as GoogleIcon } from '../../images/icons/google.svg';
import { mainTheme } from '../../mainTheme';

import { federatedSignIn } from '../../utils/cognitoAuth';
import { logger } from '../../utils/logger';
//import Modal from "@mui/material/Modal";

const SignIn = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useMountedState();

  const signIn = useCallback(
    async (email, password) => {
      setIsLoading(true);
      try {
        await Auth.signIn(email.trim(), password.trim());
        window.location.reload();
      } catch (err) {
        logger.error('Error signing in', email, err);
        setErrorMessage(err.message);

        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
      }
    },
    [isMounted]
  );

  const { search } = window.location;
  const query = new URLSearchParams(search);
  const message = query.get('message');

  const [emailConfirmationModal, setEmailConfirmationModal] = useState(false);
  const [emailConfirmationModalData, setEmailConfirmationModalData] = useState({ type: 'empty' });

  const navigate = useNavigate();
  const [, setConfirmationResult] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationCode = urlParams.get('confirmation_code');
    const userEmail = urlParams.get('email');

    if (confirmationCode) {
      // Confirm the email using the confirmation code
      Auth.confirmSignUp(userEmail, confirmationCode)
        .then(() => {
          setConfirmationResult('success');
          setEmailConfirmationModalData({ type: 'success' });
          setEmailConfirmationModal(true);
        })
        .catch((error) => {
          logger.error('Error confirming email', userEmail, error);
          setConfirmationResult({ type: 'error', error: error });
          setEmailConfirmationModalData({ type: 'error', error: 'Error: ' + error });
          setEmailConfirmationModal(true);
        });
    }
  }, [navigate]);

  function handleLogin() {
    signIn(loginEmail, loginPassword);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%'
          }}
        >
          <Typography
            color="primary.neutral"
            component="h1"
            variant="h1"
            sx={{
              mb: 1
            }}
          >
            Welcome back!
          </Typography>
          <Typography
            component="p"
            variant="p"
            sx={{
              mb: 3
            }}
          >
            We&apos;re glad to see you again. Please login below.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            {errorMessage && (
              <p className="error" style={{ fontWeight: 'bold', color: 'red', fontSize: '18px' }}>
                {' '}
                {errorMessage}{' '}
              </p>
            )}
            {message &&
              (message === 'Please activate your account' ? (
                <p className="error" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Please activate your account by clicking the link sent to the email address you provided
                </p>
              ) : (
                <p className="error" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {' '}
                  {message}{' '}
                </p>
              ))}
            <div className="signin_form">
              <div className="label_textfield">
                <span>Email Address*</span>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setLoginEmail(e.target.value.toLowerCase())}
                  value={loginEmail}
                  inputProps={{ className: 'textField' }}
                />
              </div>
              <div className="label_textfield">
                <span>Password*</span>
                <OutlinedInput
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setLoginPassword(e.target.value)}
                  value={loginPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <MdVisibility className="visible" /> : <MdVisibilityOff className="visible" />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div className="signin_options">
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <Box component="div" fontSize={14}>
                    Remember me
                  </Box>
                }
              />

              <Link
                href="/forgot-password"
                sx={{
                  textDecoration: 'underline'
                }}
              >
                Forgot password?
              </Link>
            </div>
            <div className="signin_buttons">
              <div className="signin_button">
                <Button type="submit" variant="contained" disabled={isLoading}>
                  {isLoading ? 'Signing In' : 'Sign In'}
                </Button>
                <Link href="/signup" variant="body2" sx={{ mt: 3 }} style={{ textAlign: 'right', float: 'right' }}>
                  Sign Up
                </Link>
              </div>
              <Divider>or</Divider>
              <div className="signin_providers">
                <Typography component="p" variant="p">
                  Sign In Using
                </Typography>
                <OtherButton onClick={() => federatedSignIn('Google')} variant="primary" className="button_google">
                  <GoogleIcon />
                </OtherButton>
              </div>
            </div>
          </Box>
        </Box>
      </Container>
      {emailConfirmationModal && (
        <Modal title={capitalize(emailConfirmationModalData.type)}>
          {emailConfirmationModalData.type === 'success' ? (
            <>
              <p>Email Verification Successful</p>
              <h3>Your email has been verified</h3>
            </>
          ) : (
            <>
              {/*<p>{emailConfirmationModalData.type}</p>*/}
              <p>Email Verification Failed</p>
              <h3>You may want to try again</h3>

              <p>{emailConfirmationModalData.error}</p>
            </>
          )}
          <Button
            onClick={() => {
              setEmailConfirmationModal(false);
            }}
          >
            Close
          </Button>
        </Modal>
      )}
      ;
    </ThemeProvider>
  );
};

export default SignIn;

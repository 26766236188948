import { useQuery, useQueryClient } from 'react-query';
import { getDataAPI } from '../../Helpers/FetchAPI';

const AlertBar = () => {
  useQueryClient();

  const { data: announcements } = useQuery(
    'user-announcement',
    async () => await getDataAPI('/api/announcement').then((res) => res.data)
  );

  if (announcements?.length <= 0) {
    return null;
  }

  return null;
};

export default AlertBar;

import { useContext } from 'react';
import { UserAssessmentsContext } from '../Context/UserAssessments';

const useUserAssessments = () => {
  const context = useContext(UserAssessmentsContext);

  if (!context) {
    throw new Error('useUserAssessments must be within a UserAssessmentsContextProvider');
  }

  return context;
};

export default useUserAssessments;

import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import cognitoConfig from '../config/cognitoConfig.json';
import { logger } from './logger';
import id from '../Pages/User/tools/[id]';

Amplify.configure({
  Auth: {
    userPoolId: cognitoConfig.userPool,
    userPoolWebClientId: cognitoConfig.clientId,
    region: cognitoConfig.region,
    oauth: {
      domain: cognitoConfig.userPoolUri,
      scope: cognitoConfig.tokenScopes,
      redirectSignIn: cognitoConfig.callbackUri,
      redirectSignOut: cognitoConfig.signoutUri,
      responseType: 'code'
    },
    storage: sessionStorage
  }
});

async function signUp(email, password, name = '', role = 'user') {
  return await Auth.signUp({
    username: email,
    password
  }).then(() => {
    try {
      const registerData = {
        email,
        role,
        name
      };
      axios.post(`${process.env.REACT_APP_API_URL}/auth/`, registerData);
    } catch (err) {
      logger.error('Error signing up', err);
      return err;
    }
  });
}

async function signIn(email, password) {
  return await Auth.signIn(email, password);
}

async function confirmSignUp(email, code) {
  return await Auth.confirmSignUp(email, code);
}

async function resendConfirmationCode(username) {
  return await Auth.resendSignUp(username);
}

// pass in true to sign out from all devices
async function signOut() {
  return await Auth.signOut();
}

async function federatedSignIn(provider) {
  return await Auth.federatedSignIn({ provider }).catch((err) => logger.error('Error during federated sign in', err));
}

async function forgotPassword(email) {
  return await Auth.forgotPassword(email);
}

async function forgotPasswordSubmit(email, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return 'Password was changed successfully.';
  } catch (err) {
    logger.error('Error changing password', email, err);
    throw err;
  }
}

async function changePassword(oldPassword, newPassword) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    return 'Password was changed successfully.';
  } catch (err) {
    logger.error('Error changing password', err);
    throw err;
  }
}

function getIdToken() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const idToken = data.getIdToken();
        resolve(idToken.jwtToken);
      })
      .catch((error) => {
        logger.error('Error getting id token', id, error);
        reject(Error('Not signed in.'));
      });
  });
}

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        const idToken = data.getIdToken();
        const user = idToken.payload;
        resolve(user);
      })
      .catch((error) => {
        logger.error('Error getting current user', id, error);
        reject(Error('Not signed in.'));
      });
  });
}

export {
  signUp,
  signIn,
  confirmSignUp,
  resendConfirmationCode,
  signOut,
  federatedSignIn,
  forgotPassword,
  forgotPasswordSubmit,
  getIdToken,
  changePassword,
  getCurrentUser
};

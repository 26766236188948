import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getDataAPI } from '../../Helpers/FetchAPI';
import useAuth from '../../Hooks/useAuth';
import { logger } from '../../utils/logger.js';

export const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
  const { userData, isLoading: authLoading } = useAuth();
  const [adminData, setAdminData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAdminDashboard = useCallback(async () => {
    setIsLoading(true);
    await getDataAPI('/adminDashboard')
      .then((res) => {
        setAdminData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logger.error('Error getting admin dashboard', err);
        setError(err);
        setIsLoading(true);
      });
  }, []);

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (!authLoading && userData.role === 'admin') {
        getAdminDashboard();
      }
    }

    return () => {
      isActive = false;
    };
  }, [authLoading, getAdminDashboard, userData.role]);

  const value = useMemo(() => {
    return {
      adminData,
      isLoading,
      error
    };
  }, [adminData, error, isLoading]);

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

export default AdminContextProvider;

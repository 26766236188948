/* eslint-disable no-alert */
import { useMemo, useRef } from 'react';
import { Quill } from 'react-quill';
import htmlEditButton from 'quill-html-edit-button';
import { postDataAPI } from '../Helpers/FetchAPI';

Quill.register('modules/htmlEditButton', htmlEditButton);

const ImageUpload = async (file) =>
  await postDataAPI('/workbookItems/uploads', file);

const useQuill = () => {
  const ref = useRef();
  const imageHandler = async () => {
    const editor = ref.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append('image', file);
        const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
        const url = res.imageurl;
        const server_url = process.env.REACT_APP_API_URL;
        editor.insertEmbed(editor.getSelection(), 'image', `${server_url}/${url}`);
      } else {
        alert('You could only upload images.');
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme

          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ header: 1 }, { header: 2 }], // custom button values
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ align: [] }],
          ['link', 'image', 'video', 'formula'],
          ['clean'] // remove formatting button
        ],
        handlers: {
          image: imageHandler
        }
      }
    }),
    []
  );

  const adminModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],

          [{ color: [] }, { background: [] }],

          [{ script: 'sub' }, { script: 'super' }],
          [{ header: 1 }, { header: 2 }],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],

          [{ align: [] }],
          ['link', 'image', 'video', 'formula'],
          ['clean']
        ],
        handlers: {
          image: imageHandler
        }
      },
      htmlEditButton: {
        debug: true,
        msg: 'Edit the content in HTML format',
        okText: 'Ok',
        cancelText: 'Cancel',
        buttonHTML: 'HTML',
        buttonTitle: 'Show HTML source',
        syntax: false,
        prependSelector: 'div#myelement',
        editorModules: {}
      }
    }),
    []
  );

  const theme = 'snow';

  return {
    ref,
    modules,
    theme,
    adminModules
  };
};

export default useQuill;

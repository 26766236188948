import React, { createContext, useCallback, useEffect, useState } from 'react';
import { getDataAPI } from '../../Helpers/FetchAPI';
import useAuth from '../../Hooks/useAuth';
import useLocalStorage from '../../Hooks/useLocalStorage';
import { logger } from '../../utils/logger';

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => {},
  isLoading: false,
  impersonateEmail: '',
  setImpersonateEmail: () => {},
  error: null,
  getUsers: () => {},
  userView: false,
  handleUserViewChange: () => {},
  getPaymentMethod: () => {}
});

const UserContextProvider = ({ children }) => {
  const { userData, getUser } = useAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [impersonateEmail, setImpersonateEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error] = useState(null);
  const [userView, setUserView] = useLocalStorage('UserView', true);

  const handleUserViewChange = useCallback(
    (value, cb) => {
      setUserView(value);
      localStorage.setItem('UserView', value);

      if (cb) {
        cb();
      }
    },
    [setUserView]
  );

  const getUsers = useCallback(
    async (callbackFN) => {
      try {
        if (userData && userData.role === 'admin') {
          const response = await getDataAPI('/user');

          // Check if the callback function is valid before calling it
          if (typeof callbackFN === 'function') {
            callbackFN(response.data);
          }

          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        logger.error('Error fetching users', error);
        return [];
      }
    },
    [userData]
  );

  useEffect(() => {
    let isActive = true;

    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        if (impersonateEmail) {
          const data = await getUser(impersonateEmail);
          setCurrentUser(data);
          handleUserViewChange(true);
        }
      } catch (error) {
        logger.error('Error fetching user data', error);
      } finally {
        if (isActive) {
          setIsLoading(false);
        }
      }
    };

    if (isActive) {
      if (userData) {
        if (impersonateEmail) {
          fetchUserData();
        } else {
          setIsLoading(false);
          setCurrentUser(userData);
          setImpersonateEmail('');
        }
      }
    }

    return () => {
      isActive = false;
    };
  }, [getUser, impersonateEmail, userData]);

  const getPaymentMethod = async () => {
    return await getDataAPI('/api/get-customer-info').then((res) => res.data);
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        impersonateEmail,
        setImpersonateEmail,
        isLoading,
        error,
        getUsers,
        userView,
        handleUserViewChange,
        getPaymentMethod
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

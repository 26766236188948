/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import RenderString from '../../../components/RenderString';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import useUserAssessments from '../../../Hooks/useUserAssessments';

import styles from './Assessments.module.scss';
import AssessmentItem from '../../../components/AssessmentItem';
import useUser from '../../../Hooks/useUser';
import Loading from '../../../components/Loading';
import { postDataAPI, putDataAPI } from '../../../Helpers/FetchAPI';
import { logger } from '../../../utils/logger';

const Assessment = () => {
  const { id } = useParams();
  const { assessmentsData, setAssessment, assessment, answers, setAnswers, getTakenAssessment } = useUserAssessments();
  const { currentUser } = useUser();
  const { state } = useLocation();
  const [triggerCallback, setTriggerCallback] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const entry = params.get('entry');

  useEffect(() => {
    let isActive = true;

    if (isActive) {
      if (assessmentsData.length > 0) {
        setAssessment(assessmentsData.find((item) => item._id === id));

        if (type === 'taken') {
          if (triggerCallback && currentUser) {
            setIsLoading(true);
            getTakenAssessment(entry, (data) => {
              setTriggerCallback(false);
              setAnswers(data.answers);
              setIsLoading(false);
            }).catch((err) => {
              logger.error('Error getting taken assessment', err);
              setMessage(err);
              setIsLoading(false);
              setTriggerCallback(false);
            });
          }
        }
      }
    }

    return () => {
      isActive = false;
    };
  }, [currentUser, getTakenAssessment, id, setAnswers, setAssessment, triggerCallback, assessmentsData, type, entry]);

  const handleSubmitAnswers = useCallback(
    async (values, callback) => {
      const api = type === 'taken' ? `/api/assessment/take/${entry}` : '/api/assessment/take';
      const method = type === 'taken' ? 'put' : 'post';
      const runAPI = {
        put: putDataAPI,
        post: postDataAPI
      };

      await runAPI[method](api, {
        assessmentId: id,
        userId: currentUser._id,
        answers: values
      }).then((res) => {
        const { success } = res.data;

        if (success) {
          setTriggerCallback(true);
          if (callback) {
            callback();
          }
        }
      });
    },
    [currentUser, id, type, entry]
  );

  const renderAssessment = useMemo(() => {
    switch (true) {
      case isLoading:
        return <Loading />;
      case assessment && !isLoading:
        return (
          <div className={styles.assessment}>
            <Title>{assessment.title}</Title>
            <div className={styles.assessment_container}>
              <div className={styles.container}>
                <RenderString content={assessment.description} className={styles.assessment_label} />
                <AssessmentItem
                  questions={assessment.questions}
                  type={state?.type}
                  answers={answers}
                  handleSubmitAnswers={handleSubmitAnswers}
                />
              </div>
            </div>
          </div>
        );
      case !assessment && !isLoading && message:
        return (
          <div className={styles.assessment_error}>
            <div className={styles.error_content}>
              <h1>Tool Not Found</h1>
              <Button link="/assessments">
                <ArrowBack />
                Go Back
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [answers, assessment, handleSubmitAnswers, isLoading, message]);

  return renderAssessment;
};

export default Assessment;

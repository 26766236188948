import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CampaignIcon from '@mui/icons-material/Campaign';
import DiscountIcon from '@mui/icons-material/Discount';
import ChurchIcon from '@mui/icons-material/Church';
import ShopIcon from '@mui/icons-material/Shop';
import logo from '../../images/logo/pure_desire_logo_black.svg';
import logoMark from '../../images/logo/pd-mark.svg';
import styles from './SideBar.module.scss';
import useUser from '../../Hooks/useUser';
import Button from '../Button';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import RedeemIcon from '@mui/icons-material/Redeem';

const SideBar = () => {
  const { userView, isLoading, currentUser } = useUser();
  const { pathname } = useLocation();
  const [collapse, setCollapse] = useState(false);
  const { t } = useTranslation();

  const userSidebar = useMemo(() => {
    return [
      {
        name: t('dashboard'),
        path: '/',
        icon: HomeIcon
      },
      {
        name: t('library'),
        path: '/library',
        icon: LibraryBooksIcon
      },
      // {
      //   name: 'Recent Purchases',
      //   path: '/recent-purchases',
      //   icon: HistoryIcon
      // },
      {
        name: t('credits'),
        path: '/credits',
        icon: LocalOfferIcon
      },
      // {
      //   name: t('your_groups'),
      //   path: '/groups',
      //   icon: PeopleAltOutlinedIcon
      // },
      // {
      //   name: 'Give',
      //   path: '/give',
      //   icon: VolunteerActivismIcon
      // },
      {
        name: t('notebooks'),
        path: '/notebooks',
        icon: EditIcon
      },
      {
        name: t('tools'),
        path: '/tools',
        icon: AssignmentOutlinedIcon
      }
      // {
      //   name: t('shop'),
      //   path: '/shop',
      //   icon: ShopIcon
      // }
    ];
  }, [t]);

  const adminSidebar = useMemo(() => {
    return [
      {
        name: t('dashboard'),
        path: '/',
        icon: HomeIcon
      },
      {
        name: t('library'),
        path: '/workbook',
        icon: HorizontalSplitIcon
      },
      {
        name: t('tools'),
        path: '/tools',
        icon: AssignmentOutlinedIcon
      },
      {
        name: t('users'),
        path: '/users',
        icon: PersonOutlineIcon
      },
      {
        name: t('groups'),
        path: '/groups',
        icon: PeopleAltOutlinedIcon
      },
      {
        name: t('announcement'),
        path: '/announcement',
        icon: CampaignIcon
      },
      {
        name: t('products'),
        path: '/products',
        icon: ShopIcon
      },
      {
        name: t('Funded Projects'),
        path: '/funded-projects',
        icon: BusinessIcon
      },
      {
        name: t('donations'),
        path: '/donations',
        icon: VolunteerActivismIcon
      },
      {
        name: t('orders'),
        path: '/orders',
        icon: ShoppingBagIcon
      },
      {
        name: t('coupons'),
        path: '/coupons',
        icon: DiscountIcon
      },
      {
        name: t('churches'),
        path: '/churches',
        icon: ChurchIcon
      },
      {
        name: t('Site Settings'),
        path: '/site-settings',
        icon: SettingsApplicationsIcon
      },
      {
        name: t('claims'),
        path: '/claims',
        icon: RedeemIcon
      }
      // {
      //   name: t('disclaimers'),
      //   path: '/disclaimers',
      //   icon: ErrorIcon
      // }
    ];
  }, [t]);

  const sidebar = useMemo(() => {
    switch (true) {
      case currentUser?.role === 'admin' && !userView:
        return adminSidebar;
      case currentUser?.role === 'admin' && userView:
      case currentUser?.role === 'user':
      default:
        return userSidebar;
    }
  }, [adminSidebar, currentUser?.role, userSidebar, userView]);

  const imageLogo = collapse ? logoMark : logo;

  function matchingPath(pathname, path) {
    if (path === '/' && pathname === '/') {
      return true;
    } else {
      const paths = pathname.split('/');
      return paths.filter((item) => item !== '').some((part) => path.includes(part));
    }
  }

  return (
    <div className={`${styles.sidebar} ${collapse && styles.collapse}`}>
      <div className={styles.menu_container}>
        <Link to="/" className={styles.sidebar_logo}>
          <img
            src={imageLogo}
            alt="Pure Desire Ministries"
            className={`${styles.logo_image} ${collapse && styles.collapse_logo}`}
          />
        </Link>
        <div className={`${styles.sidebar_menu} ${collapse && styles.collapse_menu}`}>
          {!isLoading &&
            sidebar.map((item, i) => (
              <Link
                key={`menu-${i}`}
                to={item.path}
                className={`${styles.menu_item} ${matchingPath(pathname, item.path) && styles.active} ${
                  collapse && styles.collapse_item
                }`}
              >
                <span className={styles.menu_icon}>
                  <item.icon
                    sx={{
                      color: 'inherit'
                    }}
                    fontSize="medium"
                  />
                </span>
                {!collapse && <span>{item.name}</span>}
              </Link>
            ))}

          {collapse && userView && (
            <Link to="/contact" className={`${styles.menu_item} ${collapse && styles.collapse_item}`}>
              <span className={styles.menu_icon}>
                <QuestionMarkIcon sx={{ color: '#00998c' }} />
              </span>
            </Link>
          )}
        </div>
      </div>

      {!collapse && userView && (
        <div className={styles.sidebar_contact}>
          <span className={styles.contact_icon}>
            <QuestionMarkIcon sx={{ color: '#00998c' }} />
          </span>
          <h1>{t('Have questions?')}</h1>
          <p>{t('Contact us if you need assistance.')}</p>
          <Link to="/faq" className={styles.contact_us}>
            {t('FAQ')}
          </Link>
          <Link to="/contact" className={styles.contact_us}>
            {t('Contact Us')}
          </Link>
        </div>
      )}
      <div className={styles.collapseButton}>
        <Button variant="primary" onClick={() => setCollapse(!collapse)}>
          {collapse && t('Expand')}
          {collapse ? <ChevronRight sx={{ color: '#00998c' }} /> : <ChevronLeft sx={{ color: '#00998c' }} />}
          {!collapse && t('Collapse')}
        </Button>
      </div>
    </div>
  );
};

export default SideBar;

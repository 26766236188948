import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Authentication } from '../views/Authentication';
import Loading from '../components/Loading';
import { logger } from '../utils/logger.js';

export const withAuthentication = (WrappedComponent) => {
  return (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const getAuthenticatedUser = useCallback(async () => {
      setIsLoading(true);
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        logger.error('Error getting authenticated user', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    }, []);

    useEffect(() => {
      let isActive = true;

      if (isActive) {
        getAuthenticatedUser();
      }

      return () => {
        isActive = false;
      };
    }, [getAuthenticatedUser]);

    if (isLoading) {
      return <Loading />;
    }

    if (!isLoading && !isAuthenticated) {
      return <Authentication />;
    }

    return <WrappedComponent {...props} isAuthenticated={isAuthenticated} />;
  };
};

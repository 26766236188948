import axios from 'axios';

class Logger {
  debug(msg, ...metadata) {
    console.debug(msg, ...metadata);
  }

  info(msg, ...metadata) {
    console.info(msg, ...metadata);
    this._sendLog('info', msg, this._constructMetadata(metadata));
  }

  error(msg, ...metadata) {
    console.error(msg, ...metadata);
    this._sendLog('error', msg, this._constructMetadata(metadata));
  }

  _constructMetadata(...metadata) {
    const returnData = {};
    metadata.forEach((entry, index) => {
      returnData[index] = entry;
    });
    return returnData;
  }

  _sendLog(level, msg, metadata) {
    axios.post(
      `${process.env.REACT_APP_API_URL}/client/log`,
      { level: level, message: msg, metadata: metadata },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }
}

export const logger = new Logger();
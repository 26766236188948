import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Button, FormControl, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation, useQuery } from 'react-query';
import Modal from '../../components/Modal';
import Search from '../../components/searchBar';
import Alert from '@mui/material/Alert';
import Title from '../../components/Title';
import useUser from '../../Hooks/useUser';
import { getDataAPI, postDataAPI, putDataAPI } from '../../Helpers/FetchAPI';
import { logger } from '../../utils/logger.js';

const WorkbookList = () => {
  const languageMap = [
    { val: 'en', english: 'English', label: 'English', dir: 'ltr', active: true },
    { val: 'es', english: 'Spanish', label: 'Español', dir: 'ltr', active: false },
    { val: 'es-us', english: 'Spanish', label: '(US)', dir: 'ltr', active: false },
    { val: 'es-mx', english: 'French', label: '(MX)', dir: 'ltr', active: false },
    { val: 'ru', english: 'Russian', label: 'Русский', dir: 'ltr', active: false },
    { val: 'fr', english: 'French', label: 'Français', dir: 'ltr', active: false }
  ];

  useFormik({
    initialValues: {
      language: 'select'
    },
    enableReinitialize: true
  });

  const workbookRef = useRef(null);
  const [queryUpdate, setQueryUpdate] = useState(false);
  const workBookQuery = useQuery(['workbook', queryUpdate], () => getDataAPI(`/workbook`).then((res) => res.data));
  const workbooks = workBookQuery.data || [];

  const { currentUser } = useUser();

  const [query, setQuery] = useState('');
  useNavigate();

  const [disableWorkbook, setDisableWorkbook] = useState(false);

  const [currentWorkbook, setCurrentWorkbook] = useState();

  const [translateModalWorkbook, setTranslateModalWorkbook] = useState(false);
  const [language, setLanguage] = useState('select');
  const [creditModalWorkbook, setCreditModalWorkbook] = useState(false);
  const [workbookState, setWorkbookState] = useState();
  const [workbookId, setWorkbookId] = useState();

  const [creditQty, setCreditQty] = useState();
  const [creditEmail, setCreditEmail] = useState();

  const [snackBar, setSnackBar] = useState({ severity: '', message: '', open: false });
  //const [snackBarFail, setSnackBarFail] = useState();

  const [workbookLang, setWorkbookLang] = useState();

  const enrolled = 0; // Placeholder for when # of enrollements eventuall gets updated
  //let transition; // Placeholder for transition feature

  const TranslateSelect = () => {
    return (
      // <FormControl  variant="outlined" fullWidth style={{ minWidth: '120px', marginTop: '17px' }}>
      //   <InputLabel>State</InputLabel>
      //   <Select value={language} label="State" name="state" onChange={handleTranslateWorkbook}>
      //     <MenuItem value="WA">WA</MenuItem>
      //     <MenuItem value="ID">ID</MenuItem>
      //   </Select>
      // </FormControl>
      <FormControl sx={{ width: '100%' }}>
        <Select
          value={language}
          name="type"
          onChange={handleTranslateWorkbook}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '4px',
                  borderColor: '#EEE',
                  borderTop: 0
                }
              }
            }
          }}
        >
          <MenuItem disabled value="select">
            Select...
          </MenuItem>
          {languageMap &&
            languageMap.map((res) => (
              <MenuItem value={res.val} key={res.label}>
                {res.english} - {res.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const handleTranslateWorkbook = (e) => {
    setLanguage(e.target.value);
    setWorkbookLang(e.target.value);
  };

  const workBookUpdateMutation = useMutation(
    (params) => {
      return putDataAPI(`/workbook/${params.id}`, params);
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries("book ")
        window.location.reload();
      },
      onError: (err) => {
        alert(err);
      }
    }
  );

  // useEffect(() => {
  //   const body = document.querySelector('body');
  //   body.style.overflow = isOpen ? 'hidden' : 'auto';
  // }, [isOpen])

  const handleCreditChange = (params) => {
    const target = params.target;

    target.type === 'number'
      ? setCreditQty(target.value)
      : target.value
        ? setCreditEmail(target.value.toLowerCase())
        : ``;
  };

  function duplicateWorkbook(workbookData) {
    postDataAPI('/workbook/duplicate-workbook', workbookData)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        } else Promise.reject();
      })
      .catch((err) => alert(err));
  }

  function translateWorkbook(workbookData) {
    workbookData.language = workbookLang;

    postDataAPI('/workbook/translate-workbook', workbookData)
      .then((res) => {
        if (res.status === 200) {
          setSnackBar({ severity: 'success', message: 'Workbook Translation Created Successfully', open: true });
          setQueryUpdate(!queryUpdate);
        } else {
          Promise.reject();
          setSnackBar({
            severity: 'error',
            message: 'Something went wrong while trying to create workbook translation',
            open: true
          });
        }
      })
      .catch((err) => alert(err));
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar({ severity: '', message: '', open: false });
  };

  return (
    <>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity={snackBar.severity} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="userToolbar"
          >
            <Grid item xs={12} lg={6}>
              <Title>Resources</Title>
            </Grid>
            <Grid item xs={12} md="auto">
              <Search query={query} setQuery={setQuery} />
            </Grid>

            <Grid item xs={12} lg="auto">
              <Link to="/workbook/create-workbook" className="buttonLink">
                Create New Resource
              </Link>
            </Grid>
          </Grid>
          <div>
            {!!workbooks.length &&
              workbooks
                .filter((res) => {
                  if (res.parentWorkbook === undefined) {
                    if (query == '') {
                      return res;
                    } else if (res.title.toLowerCase().includes(query.toLowerCase())) {
                      return res;
                    } else if (res.description.toLowerCase().includes(query.toLowerCase())) {
                      return res;
                    } else if (res.sku.toLowerCase().includes(query.toLowerCase())) {
                      return res;
                    }
                  }
                })
                .map((workbook) => (
                  <Accordion key={workbook._id} ref={workbookRef}>
                    <AccordionSummary
                      aria-controls={'panel1a-content'}
                      id={'panel1a-header'}
                      className="accordionToggle single"
                    >
                      <div className="accordionImage">
                        {workbook.file_cover ? (
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${workbook.file_cover}`}
                            style={{
                              width: '100%',
                              objectFit: 'cover',
                              height: 'auto'
                            }}
                          />
                        ) : (
                          <img src="https://placehold.jp/98x98.png" />
                        )}
                      </div>
                      <ExpandMoreIcon />
                      <h2 className="accordionTitle">{workbook.title}</h2>
                      {/*<div className="accordionStats">*/}
                      {/*  <span>*/}
                      {/*    Enrolled <strong>{enrolled}</strong>*/}
                      {/*  </span>*/}
                      {/*  <span>*/}
                      {/*    Completed <strong>0</strong>*/}
                      {/*  </span>*/}
                      {/*  <span>*/}
                      {/*    Unused Credits <strong>0</strong>*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                      <div className="accordionBtns">
                        <Link
                          className="buttonLink"
                          style={{ marginRight: '10px', minWidth: '110px' }}
                          onClick={() => {
                            setCurrentWorkbook(workbook);
                            setCreditModalWorkbook(true);
                          }}
                        >
                          Give Credit
                        </Link>
                        <Link
                          className="buttonLink"
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            setCurrentWorkbook(workbook);
                            setTranslateModalWorkbook(true);
                          }}
                        >
                          Language
                        </Link>

                        <Link className="buttonLink" style={{ marginRight: '10px' }} to={`/workbook/${workbook._id}`}>
                          Edit
                        </Link>
                        <Link
                          href="#"
                          className="buttonLink outline"
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            duplicateWorkbook(workbook);
                          }}
                        >
                          Duplicate
                        </Link>
                        <Button
                          disabled={enrolled > 0}
                          href="#"
                          className="buttonLink outline red"
                          onClick={() => {
                            setWorkbookId(workbook._id);
                            setDisableWorkbook(true);
                            setWorkbookState(workbook.disabled);
                          }}
                        >
                          {workbook.disabled ? 'Enable' : 'Disable'}
                        </Button>
                      </div>
                    </AccordionSummary>
                    {workbooks
                      .filter((lang) => {
                        if (lang.parentWorkbook === workbook._id) {
                          return lang;
                        }
                      })
                      .map((workbookLang) => (
                        <AccordionDetails
                          sx={{ pb: 2, pl: 8 }}
                          style={{ borderTop: '1px solid #e0e0e0' }}
                          className="accordionDetails"
                          key={workbookLang.title}
                        >
                          <h2 className="accordionTitle">{workbookLang.title}</h2>
                          <div className="accordionStats">
                            <span>
                              Enrolled <strong>{enrolled}</strong>
                            </span>
                            <span>
                              Completed <strong>0</strong>
                            </span>
                            <span>
                              Unused Credits <strong>0</strong>
                            </span>
                          </div>
                          <div className="accordionBtns">
                            <Link
                              className="buttonLink"
                              style={{ marginRight: '10px' }}
                              to={`/workbook/${workbookLang._id}`}
                            >
                              Edit
                            </Link>
                            <Button
                              disabled={enrolled > 0}
                              href="#"
                              className="buttonLink outline red"
                              onClick={() => {
                                setWorkbookId(workbook._id);
                                setDisableWorkbook(true);
                                setWorkbookState(workbook.disabled);
                              }}
                            >
                              {workbookLang.disabled ? 'Enable' : 'Disable'}
                            </Button>
                          </div>
                        </AccordionDetails>
                      ))}
                  </Accordion>
                ))}
          </div>
        </Paper>
      </Grid>

      {disableWorkbook && (
        <Modal
          open={disableWorkbook}
          // onClose={() => }
          style={{ overflow: 'scroll' }}
        >
          <Box className="delete-modal">
            <div>
              <Typography variant="h1" style={{ padding: '10px' }}>
                Disable
              </Typography>
              <Typography>Are you sure you want to disable this workbook?</Typography>
              <Grid container justifyContent="space-around" style={{ paddingTop: '30px' }}>
                <Grid item>
                  <Button
                    className="btn secondary"
                    onClick={() => {
                      setDisableWorkbook(false);
                      // setActiveItem({})
                    }}
                  >
                    <b>No, go back</b>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className="btn third"
                    onClick={() => {
                      setDisableWorkbook(false);
                      workBookUpdateMutation.mutate({ state: !workbookState, id: workbookId });
                    }}
                  >
                    <b>Yes, I’m sure</b>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Modal>
      )}
      {translateModalWorkbook && (
        <Modal
          open={translateModalWorkbook}
          // onClose={() => }
          style={{ overflow: 'scroll' }}
        >
          <Box className="delete-modal">
            {/*<FormControl>*/}
            <Title>Language</Title>
            <Typography>Please Select Desired Language</Typography>

            <TranslateSelect />
            <Grid container justifyContent="space-around" style={{ paddingTop: '30px' }}>
              <Grid item>
                <Button
                  className="btn secondary"
                  onClick={() => {
                    setTranslateModalWorkbook(false);
                    // setActiveItem({})
                  }}
                >
                  <b>Go back</b>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="btn third"
                  onClick={() => {
                    setTranslateModalWorkbook(false);
                    translateWorkbook(currentWorkbook);
                  }}
                >
                  <b>Submit</b>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
      {creditModalWorkbook && (
        <Modal
          open={creditModalWorkbook}
          className="workbookbtn-modal"
          // onClose={() => }
          style={{ overflow: 'scroll' }}
        >
          <Box style={{ width: '100%' }} className="delete-modal">
            <div>
              <Typography variant="h1" style={{ padding: '10px' }}>
                Give Credit
              </Typography>
              <Typography style={{ padding: '10px' }}>Email</Typography>
              {/*<label>Email</label>*/}
              <TextField
                type="text"
                margin="normal"
                required
                style={{ width: '100%', paddingBottom: '10px', paddingTop: '-10px' }}
                //id="requiredScore"
                //name="requiredScore"
                // label="Section Title"
                placeholder="example@abc.com"
                //value={formik.values.requiredScore}
                onChange={handleCreditChange}
                //error={formik.touched.requiredScore && Boolean(formik.errors.requiredScore)}
              />
              <Typography style={{ padding: '10px' }}>Quantity</Typography>
              {/*<label>Email</label>*/}
              <TextField
                type="number"
                margin="normal"
                style={{ width: '100%', paddingBottom: '10px', paddingTop: '-10px' }}
                //id="requiredScore"
                //name="requiredScore"
                // label="Section Title"
                placeholder="example@abc.com"
                defaultValue={1}
                onChange={handleCreditChange}
                //error={formik.touched.requiredScore && Boolean(formik.errors.requiredScore)}
              />

              <Grid container justifyContent="space-around" style={{ paddingTop: '30px' }}>
                <Grid item>
                  <Link
                    className="btn secondary"
                    onClick={() => {
                      setCreditModalWorkbook(false);
                      // setActiveItem({})
                    }}
                  >
                    <b>Go back</b>
                  </Link>
                </Grid>
                <Grid item>
                  <Button
                    className="btn third"
                    onClick={async () => {
                      setCreditModalWorkbook(false);

                      const email = currentUser.email;

                      const token = await postDataAPI('/v3/getToken', { email }).then((res) => res.data.token);

                      let data = JSON.stringify({
                        userEmail: creditEmail,
                        total: '0',
                        userName: currentUser.name,
                        woocommerce_id: '123',
                        items: [
                          {
                            workbookSku: currentWorkbook.sku,
                            workbookName: currentWorkbook.title,
                            price: '0',
                            quantity: creditQty ? creditQty : 1
                          }
                        ]
                      });
                      //let urli = process.env.REACT_APP_API_URL;
                      let config = {
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + '/v3/purchaseWorkbook/',
                        headers: {
                          token,
                          'Content-Type': 'application/json'
                        },
                        data: data
                      };

                      axios(config)
                        .then(function (response) {
                          response.data.success === true
                            ? setSnackBar({
                                severity: 'success',
                                message: 'Credit Transferred Successfully',
                                open: true
                              })
                            : setSnackBar({
                                severity: 'error',
                                message: 'There was an error while trying to send credit.',
                                open: true
                              });
                        })
                        .catch(function (error) {
                          logger.error('Error sending credit', error);
                          setSnackBar({
                            severity: 'error',
                            message: 'There was an error while trying to send credit.',
                            open: true
                          });
                        });
                    }}
                  >
                    <b>Submit</b>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default WorkbookList;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, forgotPasswordSubmit } from '../../utils/cognitoAuth';
import { logger } from '../../utils/logger';

export default function SetPassword() {
  const navigate = useNavigate();

  const [setPasswordEmail, setSetPasswordEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [show, setShow] = useState(false);

  function handleSetPassword(e) {
    e.preventDefault();
    forgotPassword(setPasswordEmail)
      .then(() => {
        setShow(true);
      })
      .catch((err) => logger.error('Error setting password', setPasswordEmail, err));
  }

  function handleSubmitPassword(e) {
    e.preventDefault();

    forgotPasswordSubmit(setPasswordEmail, code, password)
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        logger.error('Error resetting password', setSetPasswordEmail, err);
        alert(err.message);
      });
  }

  return (
    <>
      <h3>Claim your password</h3>
      {!show && (
        <form onSubmit={handleSetPassword}>
          <input
            required
            type="email"
            placeholder="email"
            value={setPasswordEmail}
            onChange={(e) => setSetPasswordEmail(e.target.value)}
          />
          <button>Send a Code</button>
        </form>
      )}
      {show && (
        <form
          style={{
            maxWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            rowGap: '15px'
          }}
          onSubmit={handleSubmitPassword}
        >
          <input
            required
            type="email"
            placeholder="email"
            value={setPasswordEmail}
            onChange={(e) => setSetPasswordEmail(e.target.value)}
          />

          <input required type="text" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} />

          <input required type="text" placeholder="code" value={code} onChange={(e) => setCode(e.target.value)} />

          <input
            required
            type="password"
            placeholder="new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button type="submit">Set Password</button>
          <span>
            <i>
              Didn't get a code? Resend it <a href="/set-password">here</a>
            </i>
          </span>
        </form>
      )}
    </>
  );
}

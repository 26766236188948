/* eslint-disable no-empty */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Divider, Grid, Snackbar, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQueryClient } from 'react-query';
import Alert from '@mui/material/Alert';
import { ProgressBar } from '../../atoms';
import EditWorkbook from './EditWorkBook';
import PreviewWorkbooks from './previewWorkbook';
import Modal from '../../Modal';
import RenderString from '../../RenderString';
import { logger } from '../../../utils/logger';
import { postDataAPI, putDataAPI } from '../../../Helpers/FetchAPI';

const CreateSection = ({ workBookId, workBook }) => {
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [editWorkbook, setEditWorkbook] = useState(false);
  const [progress, setProgress] = useState(0);
  const [headerImage, setHeaderImage] = useState([]);
  const [uploadingImg, setUploadingImg] = useState(false);
  const [uploadingPdf, setUploadingPdf] = useState(false);
  const [pdfDownload, setPdfDownload] = useState([]);
  const [preview, setPreview] = useState(false);
  const [snack, setSnack] = useState(false);

  const SuccessNotif = () => {
    return (
      <Snackbar autoHideDuration={6000} open={snack} onClose={handleClose}>
        <Alert severity="success">Save Successful!</Alert>
      </Snackbar>
    );
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack(false);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
  });

  const addSectionMutation = useMutation(
    (params) => {
      return putDataAPI(`/workbook/add-section/${workBookId}`, params, {
        maxContentLength: 10000000,
        maxBodyLength: 10000000
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bookSection');
        setOpen(false);
      },
      onError: (err) => {
        alert(err);
      }
    }
  );

  const workBookUpdateMutation = useMutation(
    (params) => {
      return putDataAPI(`/workbook/${workBookId}`, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bookSection');
      },
      onError: (err) => {
        alert(err);
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      s_header_image: '',
      s_pdf_download: ''
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const params = {
        sections: [
          {
            title: values.title,
            description: values.description,
            s_header_image: values.s_header_image,
            s_pdf_download: values.s_pdf_download
          }
        ]
      };
      addSectionMutation.mutate(params);
      // resetForm()
      setHeaderImage([]);
      setPdfDownload([]);
      // axios
      //   .put(`http://localhost:5000/workbook/add-section/${workBookId}`, params)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       setOpen(false)
      //     } else Promise.reject();
      //   })
      //   .catch((err) => alert(err));
    }
  });

  const UploadImage = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: { 'image/*': [] },
      onDrop: (acceptedFiles) => {
        const config = {
          headers: {
            'content-type': 'multipart/form-data;boundary=MyBoundary'
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          }
        };
        const fd = new FormData();
        fd.append('s_header_image', acceptedFiles[0]);
        setHeaderImage(acceptedFiles);
        setUploadingImg(acceptedFiles.length > 0);
        postDataAPI(`/workbook/uploads`, fd, config)
          .then((res) => {
            setUploadingImg(false);
            if (res.status === 200) {
              formik.setFieldValue('s_header_image', res.data.s_header_image);
            } else Promise.reject();
          })
          .catch(() => alert('Upload a valid file format or reduce the file size.'));
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          <Typography style={{ fontSize: '18px', fontWeight: '500', color: '#00000099' }}>Drag and Drop</Typography>
          <Typography style={{ fontSize: '14px' }}>your files here, or browse</Typography>
        </div>
      </div>
    );
  };

  const UploadPDF = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: { 'application/pdf': [] },
      onDrop: (acceptedFiles) => {
        const config = {
          headers: {
            'content-type': 'multipart/form-data;boundary=MyBoundary'
          },
          maxContentLength: 10000000,
          maxBodyLength: 10000000,
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          }
        };
        const fd = new FormData();
        fd.append('s_pdf_download', acceptedFiles[0]);
        setPdfDownload(acceptedFiles);
        setUploadingPdf(acceptedFiles.length > 0);
        postDataAPI(`/workbook/uploads`, fd, config)
          .then((res) => {
            setUploadingPdf(false);
            if (res.status === 200) {
              formik.setFieldValue('s_pdf_download', res.data.s_pdf_download);
            } else Promise.reject();
          })
          .catch(() => alert('Upload a valid file format or reduce the file size.'));
      }
    });
    return (
      <div>
        {}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} multiple={false} />
          <Typography style={{ fontSize: '18px', fontWeight: '500', color: '#00000099' }}>Drag and Drop</Typography>
          <Typography style={{ fontSize: '14px' }}>your files here, or browse</Typography>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setOpen(false);
  };

  const removeMedia = (path) => {
    let imgPath = '';
    if (formik.values.s_header_image.includes(path)) imgPath = formik.values.s_header_image;
    else if (formik.values.s_pdf_download.includes(path)) imgPath = formik.values.s_pdf_download;

    if (imgPath) {
      const data = {
        path: imgPath
      };

      postDataAPI(`/workbook/delete`, data)
        .then((res) => {
          if (res.status === 200) {
          } else Promise.reject();
        })
        .catch((err) => {
          logger.error('Error deleting workbook', data, err);
        });

      if (formik.values.s_header_image.includes(path)) {
        setHeaderImage([]);
        formik.setFieldValue('s_header_image', '');
      } else if (formik.values.s_pdf_download.includes(path)) {
        setPdfDownload([]);
        formik.setFieldValue('s_pdf_download', '');
      }
    }
  };

  return (
    <>
      <SuccessNotif />
      {open && (
        <Modal>
          <div
            style={{
              height: '100%',
              width: '100%',
              overflowY: 'auto'
            }}
          >
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="modal-wrap" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box className="modal-header" style={{ maxHeight: 'inherit' }}>
                    <Typography variant="h2"> {workBook.title} </Typography>
                    <Typography variant="body1">
                      <RenderString content={workBook.description} />
                    </Typography>
                    <Divider style={{ margin: '20px 0' }} />
                    <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <label htmlFor="file" className="label">
                          Section Title*
                        </label>
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="title"
                          name="title"
                          // label="Section Title"
                          placeholder="Type here..."
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          error={formik.touched.title && Boolean(formik.errors.title)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <label htmlFor="file" className="label">
                          Section Description
                        </label>
                        <TextField
                          margin="normal"
                          fullWidth
                          id="description"
                          name="description"
                          // label="Section Description"
                          placeholder="Type here..."
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          error={formik.touched.description && Boolean(formik.errors.description)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider style={{ margin: '20px 0' }} />

                  <Box className="modal-body" style={{ width: '100%' }}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      className="fileDrop"
                    >
                      <Grid item xs={12} sm={6}>
                        <div className="form-group" style={{ marginBottom: '0', marginTop: '30px' }}>
                          <label htmlFor="file" className="label">
                            Section Header Image
                          </label>
                          <UploadImage />
                          <Typography style={{ padding: '10px 0', fontSize: '14px', color: '#00000099' }}>
                            Recommended dimensions: 1200x500
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <div className="form-group" style={{ marginBottom: '0', marginTop: '30px' }}>
                          <label htmlFor="file" className="label">
                            PDF Download
                          </label>
                          <UploadPDF />
                        </div>
                        <ProgressBar
                          file={headerImage}
                          uploading={uploadingImg}
                          progress={progress}
                          removeMedia={removeMedia}
                        />
                        <ProgressBar
                          file={pdfDownload}
                          uploading={uploadingPdf}
                          progress={progress}
                          removeMedia={removeMedia}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="modal-footer" style={{ width: '100%', maxHeight: 'inherit' }}>
                    <Divider style={{ margin: '20px 0' }} />
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="flex-end"
                      style={{ marginTop: '0' }}
                      alignItems="center"
                    >
                      <Grid item align="right">
                        <Button className="buttonLink outline red" onClick={closeModal}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item align="right">
                        <Button className="buttonLink" type="submit">
                          Save and Exit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </form>
            </FormikProvider>
          </div>
        </Modal>
      )}
      {editWorkbook && (
        <EditWorkbook snack={snack} setSnack={setSnack} close={() => setEditWorkbook(false)} workBook={workBook} />
      )}
      {preview && <PreviewWorkbooks setPreview={() => setPreview(false)} workbook={workBook} workBookId={workBookId} />}
      <Box
        className="dashed-border"
        sx={{
          minHeight: '150px',
          borderRadius: '4px',
          margin: '20px 0',
          cursor: 'pointer'
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddCircleIcon className="position-center" style={{ width: '50px', height: '50px' }} />
      </Box>
      <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button className="buttonLink outline" onClick={() => setEditWorkbook(true)}>
            Workbook Settings
          </Button>
        </Grid>
        <Grid item>
          <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button className="buttonLink outline" onClick={() => setPreview(true)}>
                Preview
              </Button>
            </Grid>
            <Grid item>
              <Button
                className="buttonLink"
                onClick={() => workBookUpdateMutation.mutate({ state: !workBook.disabled, id: workBookId })}
              >
                {workBook?.disabled ? 'Enable' : 'Disable'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateSection;

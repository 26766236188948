import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../../components/Button';
import styles from '../PageNotFound/PageNotFound.module.scss';
import Title from '../../components/Title';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const ErrorPage = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <div
      className={styles.notfound}
      style={{
        minHeight: '100vh'
      }}
    >
      <div className={styles.notfound_content}>
        <Title>Something went wrong!</Title>
        <p>This is not the page you&apos;re looking for.</p>
        <Button onClick={() => navigate('/')}>
          <ArrowBackIcon />
          Go Back
        </Button>

        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
            <Typography>More Error Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {error.toString()}
            <pre>{error.stack}</pre>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ErrorPage;
